/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Radio, notification } from 'antd';
import Card from '../card/card';
import cards from '../../cards.json';

const ResolveDestroy = (props) => {
	const [selectedCard, setSelectedCard] = useState('');

	const cardsToRender = () => {
		let arrToReturn = [];

		if (props.cardsToResolve().length === 0) {
			return arrToReturn;
		}

		arrToReturn = props
			.cardsToResolve()
			.filter((card) => !props.cardsToSkipDestroyResolve.includes(card));

		if (arrToReturn.length === 0 && props.showTheResolveDestroy) {
			notification['warning']({
				message: 'Destroy',
				description: 'Not enough eligible cards to perform the resolve!',
			});

			if (props.callback) {
				props.callback('');
			}
		}

		// sorting cards by lifePoints first, then by fightValue
		arrToReturn.sort(function (a, b) {
			const cb = cards[b];
			const ca = cards[a];
			return cb.lifePoints - ca.lifePoints || ca.fightValue - cb.fightValue;
		});

		return arrToReturn;
	};

	const resetState = () => {
		setSelectedCard('');
	};

	return (
		<Modal
			className={`resolveDestroy${
				props.className ? ` ${props.className}` : ''
			}`}
			visible={props.showTheResolveDestroy}
			closable={false}
			afterClose={resetState}
			destroyOnClose
			footer={[
				<Button
					type="ghost"
					onClick={() => {
						if (props.onCancel) {
							props.onCancel();
						}
					}}
				>
					Cancel
				</Button>,
				<Button
					type="primary"
					disabled={!selectedCard}
					onClick={() => {
						if (props.callback) {
							props.callback(selectedCard);
						}
					}}
				>
					Done
				</Button>,
			]}
			width="100%"
		>
			<h2 className="t-a:c">
				Select a card to destroy and remove from the game (if you choose aging
				card, it's special ability would be cancelled too)
			</h2>

			<div className="t-a:c">
				{props.showTheResolveDestroy &&
					cardsToRender().map((card) => (
						<div className={`d:i-flex marginLeft-24 marginTop-24`} key={card}>
							<Radio.Group
								onChange={(e) => {
									setSelectedCard(e.target.value);
								}}
								value={selectedCard}
							>
								{card.startsWith('h-') ? (
									<>
										<Radio value={card}>
											<Card
												id={card}
												front
												diffcultLevel={cards[card].levels.hard}
												mediumLevel={cards[card].levels.medium}
												easyLevel={cards[card].levels.easy}
												hazardDescription={cards[card].hazard}
												freeCards={cards[card].freeCards}
												imageNum={cards[card].imageNum}
												hazard
												lifePoints={cards[card].lifePoints}
												fightValue={cards[card].fightValue}
												state={cards[card].state}
												specialAbility={cards[card].specialAbility}
												className={`${
													selectedCard === card ? 'selected-no-rotation' : ''
												}`}
											/>
										</Radio>
									</>
								) : (
									<>
										<Radio value={card}>
											<Card
												id={card}
												front
												lifePoints={cards[card].lifePoints}
												fightValue={cards[card].fightValue}
												state={cards[card].state}
												specialAbility={cards[card].specialAbility}
												className={`${
													selectedCard === card ? 'selected-no-rotation' : ''
												}`}
											/>
										</Radio>
									</>
								)}
							</Radio.Group>
						</div>
					))}
			</div>
		</Modal>
	);
};

ResolveDestroy.propTypes = {
	className: PropTypes.string,
	showTheResolveDestroy: PropTypes.bool,
	cardsToResolve: PropTypes.func,
	cardsToSkipDestroyResolve: PropTypes.array,
	callback: PropTypes.func,
	onCancel: PropTypes.func,
};

export default ResolveDestroy;
