/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import Card from '../card/card';
import cards from '../../cards.json';
import './resolveSort3.css';

const ResolveSort3 = (props) => {
	const [cardsToRender, setCardsToRender] = useState([]);
	const [cardsInTheOrder, setCardsInTheOrder] = useState([]);
	const [cardToDiscard, setCardToDiscard] = useState('');

	useLayoutEffect(() => {
		if (props.top3CardsFromRobinsonDeck.length === 3) {
			setCardsToRender(props.top3CardsFromRobinsonDeck);
		}
	});

	useLayoutEffect(() => {
		if (cardsInTheOrder.length === 2) {
			// get the missing card after comparing cardsInTheOrder to cardsToRender
			const missingCard = cardsToRender.filter(
				(card) => cardsInTheOrder.indexOf(card) === -1
			)[0];
			setCardToDiscard(missingCard);
		}

		// clear cardToDiscard when the length of cardsInTheOrder is 3
		if (cardsInTheOrder.length === 3) {
			setCardToDiscard('');
		}
	}, [cardsInTheOrder]);

	const resetState = () => {
		setCardsToRender([]);
		setCardsInTheOrder([]);
		setCardToDiscard('');
	};

	return (
		<Modal
			className={`resolveSort3${props.className ? ` ${props.className}` : ''}`}
			visible={props.showTheResolveSort3}
			closable={false}
			afterClose={resetState}
			destroyOnClose
			footer={[
				<Button
					type="ghost"
					onClick={() => {
						if (props.callback) {
							props.callback('', cardsToRender);
						}
					}}
				>
					Retain default order
				</Button>,
				<Button type="ghost" onClick={resetState}>
					Reset
				</Button>,
				<Button
					type="primary"
					disabled={cardsInTheOrder.length < 2}
					onClick={() => {
						if (props.callback) {
							// reversing the order of the cardsInTheOrder array to get the right order
							props.callback(cardToDiscard, cardsInTheOrder.reverse());
						}
					}}
				>
					Done
				</Button>,
			]}
			width="100%"
		>
			<h2 className="t-a:c">
				Click on the cards in the order you want them to be in. If you want a
				card to be discarded, then don't click the card.
			</h2>

			<div className="t-a:c container">
				<div className="row fCenter">
					{cardsToRender.length > 0 &&
						cardsToRender.map((card, index) => (
							<div
								className="col-xs-12 col-md-3 fCenter"
								key={card}
								style={{ marginTop: '40px' }}
								onClick={() => {
									for (let i = 0; i < cardsInTheOrder.length; i++) {
										if (card === cardsInTheOrder[i]) {
											if (cardToDiscard === '') {
												setCardToDiscard(card);
											}
											return;
										}
									}
									setCardsInTheOrder([...cardsInTheOrder, card]);
								}}
							>
								<div>
									<div className="resolveSort3-default-ranking">
										{3 - index}
									</div>
									{card.startsWith('h-') ? (
										<>
											<Card
												id={card}
												front
												diffcultLevel={cards[card].levels.hard}
												mediumLevel={cards[card].levels.medium}
												easyLevel={cards[card].levels.easy}
												hazardDescription={cards[card].hazard}
												freeCards={cards[card].freeCards}
												imageNum={cards[card].imageNum}
												hazard
												lifePoints={cards[card].lifePoints}
												fightValue={cards[card].fightValue}
												state={cards[card].state}
												specialAbility={cards[card].specialAbility}
												className={
													cardsInTheOrder.includes(card)
														? 'selected-no-rotation'
														: ''
												}
											/>
										</>
									) : (
										<>
											<Card
												id={card}
												front
												lifePoints={cards[card].lifePoints}
												fightValue={cards[card].fightValue}
												state={cards[card].state}
												specialAbility={cards[card].specialAbility}
												className={
													cardsInTheOrder.includes(card)
														? 'selected-no-rotation'
														: ''
												}
											/>
										</>
									)}
									<div className="resolveSort3-ranking">
										<div>
											{cardsInTheOrder.indexOf(card) + 1 > 0
												? `${cardsInTheOrder.indexOf(card) + 1}`
												: ''}
										</div>
									</div>
								</div>
							</div>
						))}
				</div>
				<div className="marginTop-24">
					<p>Note: Make sure at least two cards are selected.</p>
					{cardsInTheOrder.length === 2 && (
						<p>
							<b>
								{' '}
								The only unselected card will be discarded unless selected!{' '}
							</b>
						</p>
					)}
				</div>
			</div>
		</Modal>
	);
};

ResolveSort3.propTypes = {
	className: PropTypes.string,
	showTheResolveSort3: PropTypes.bool,
	top3CardsFromRobinsonDeck: PropTypes.array,
	callback: PropTypes.func,
};

export default ResolveSort3;
