/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Radio, notification } from 'antd';
import Card from '../card/card';
import cards from '../../cards.json';

const ResolveExchange = (props) => {
	const [selectedCard, setSelectedCard] = useState([]);
	const [abilitiesAlreadyUsed, setAbilitiesAlreadyUsed] = useState([]);

	useLayoutEffect(() => {
		if (props.showTheResolveExchange) {
			setAbilitiesAlreadyUsed(props.alreadyUsedCards());
		}
	}, [props.alreadyUsedCards, props.showTheResolveExchange]);

	const cardsToRender = () => {
		let arrToReturn = [];

		if (props.cardsToResolve().length === 0) {
			return arrToReturn;
		}

		if (props.cardsToResolve && cards) {
			arrToReturn = props
				.cardsToResolve()
				.filter((card) => !props.cardsAlreadyDestroyed.includes(card));
		}

		if (arrToReturn.length === 0 && props.showTheResolveExchange) {
			notification['warning']({
				message: 'Resolve exchange modal',
				description: 'Not enough eligible cards to perform the resolve!',
			});

			if (props.callback) {
				props.callback();
			}
		}

		arrToReturn.sort((a, b) => cards[a].fightValue - cards[b].fightValue);

		return arrToReturn;
	};

	const resetState = () => {
		setSelectedCard('');
		setAbilitiesAlreadyUsed([]);
	};

	return (
		<Modal
			className={`resolveExchange${
				props.className ? ` ${props.className}` : ''
			}`}
			visible={props.showTheResolveExchange}
			closable={false}
			afterClose={resetState}
			destroyOnClose
			footer={[
				<Button
					onClick={() => {
						if (props.onCancel) {
							props.onCancel();
						}
					}}
				>
					Cancel
				</Button>,
				<Button
					type="primary"
					disabled={!selectedCard}
					onClick={() => {
						if (props.callback) {
							props.callback(selectedCard);
						}
					}}
				>
					Done
				</Button>,
			]}
			width="100%"
		>
			<h2 className="t-a:c">
				Select a card to exchange with other cards from robinson deck
			</h2>
			<p className="t-a:c">
				<i>
					<b>Note</b>: Used cards are tilted and have a brown background.
				</i>
			</p>

			<div className="t-a:c">
				{props.showTheResolveExchange &&
					cardsToRender().map((card) => (
						<div className={`d:i-flex marginLeft-24 marginTop-24`} key={card}>
							<Radio.Group
								onChange={(e) => {
									setSelectedCard(e.target.value);
								}}
								value={selectedCard}
							>
								{card.startsWith('h-') ? (
									<>
										<Radio value={card}>
											<Card
												id={card}
												front
												diffcultLevel={cards[card].levels.hard}
												mediumLevel={cards[card].levels.medium}
												easyLevel={cards[card].levels.easy}
												hazardDescription={cards[card].hazard}
												freeCards={cards[card].freeCards}
												imageNum={cards[card].imageNum}
												hazard
												lifePoints={cards[card].lifePoints}
												fightValue={cards[card].fightValue}
												state={cards[card].state}
												specialAbility={cards[card].specialAbility}
												className={`${
													selectedCard === card
														? 'selected-no-rotation'
														: abilitiesAlreadyUsed.includes(card)
														? 'ability-already-used'
														: ''
												}`}
											/>
										</Radio>
									</>
								) : (
									<>
										<Radio value={card}>
											<Card
												id={card}
												front
												lifePoints={cards[card].lifePoints}
												fightValue={cards[card].fightValue}
												state={cards[card].state}
												specialAbility={cards[card].specialAbility}
												className={`${
													selectedCard === card
														? 'selected-no-rotation'
														: abilitiesAlreadyUsed.includes(card)
														? 'ability-already-used'
														: ''
												}`}
											/>
										</Radio>
									</>
								)}
							</Radio.Group>
						</div>
					))}
			</div>
		</Modal>
	);
};

ResolveExchange.propTypes = {
	className: PropTypes.string,
	showTheResolveExchange: PropTypes.bool,
	cardsToResolve: PropTypes.func,
	callback: PropTypes.func,
	cardsAlreadyDestroyed: PropTypes.array,
	onCancel: PropTypes.func,
	alreadyUsedCards: PropTypes.func,
};

export default ResolveExchange;
