/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Select } from 'antd';
import './startupModal.css';
// import uuid from 'react-uuid';

const StartUpModal = (props) => {
	const [selectedLevel, setSelectedLevel] = useState(1);
	const [name, setName] = useState('');
	// const [gameID, setGameID] = useState('');
	const [availableNames, setAvailableNames] = useState([]);

	const [timedMode, setTimeMode] = useState(0);

	const resetState = () => {
		setSelectedLevel(0);
	};

	useLayoutEffect(() => {
		// setGameID(uuid());
		const data = localStorage.getItem('friday');

		if (data) {
			setAvailableNames(JSON.parse(data).names);
		}
	}, []);

	return (
		<Modal
			className={`startupModal${props.className ? ` ${props.className}` : ''}`}
			visible={props.showTheStartUpModal}
			closable={false}
			afterClose={resetState}
			destroyOnClose
			footer={[
				<Button
					type="primary"
					disabled={selectedLevel === 0 || !name}
					onClick={() => {
						let data = localStorage.getItem('friday');

						if (!data) {
							const gameObj = { names: [name] };
							localStorage.setItem('friday', JSON.stringify(gameObj));
						} else {
							data = JSON.parse(data);

							if (!data.names.includes(name)) {
								data.names.push(name);
							}

							localStorage.setItem('friday', JSON.stringify(data));
						}

						if (props.callback) {
							props.callback(selectedLevel, name, timedMode);
						}
					}}
				>
					Start the game!
				</Button>,
			]}
			width="80%"
		>
			<h2 className="t-a:c">Welcome to Friday 😉!</h2>
			<div className="t-a:c">
				<h4>
					<a
						target="_blank"
						rel="noreferrer"
						href="https://www.ultraboardgames.com/friday/game-rules.php"
					>
						Rules
					</a>
				</h4>
			</div>

			<div className="t-a:c">
				<div className="marginTop-24">
					<div>
						<b>Name</b>
					</div>
					<input
						autoFocus
						className="marginTop-6"
						value={name}
						onChange={(e) => setName(e.currentTarget.value)}
					/>
				</div>

				{availableNames.map((name, i) => (
					<p
						key={name}
						style={{ marginLeft: i > 0 ? '10px' : '' }}
						className="existingName"
						tabIndex="0"
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								setName(e.currentTarget.innerText);
							}
						}}
						onClick={(e) => {
							setName(e.currentTarget.innerText);
						}}
					>
						{name}
					</p>
				))}

				<div className="marginTop-24">
					<div>
						<b>Timed Mode</b>
					</div>
					<Select
						className="marginTop-6"
						placeholder="Timed mode"
						onChange={(selectedTimedMode) => setTimeMode(selectedTimedMode)}
						value={timedMode}
					>
						<Select.Option value={0}>None</Select.Option>
						<Select.Option value={1}>5 min</Select.Option>
						<Select.Option value={2}>7 min</Select.Option>
						<Select.Option value={3}>9 min</Select.Option>
						<Select.Option value={4}>12 min</Select.Option>
						<Select.Option value={5}>15 min</Select.Option>
					</Select>
				</div>

				<div className="marginTop-24">
					<div>
						<b>Level</b>
					</div>
					<Select
						className="marginTop-6"
						placeholder="Level"
						onChange={(level) => setSelectedLevel(level)}
						dropdownMatchSelectWidth={false}
						value={selectedLevel}
					>
						<Select.Option value={1}>1</Select.Option>
						<Select.Option value={2}>2</Select.Option>
						<Select.Option value={3}>3</Select.Option>
						<Select.Option value={4}>4</Select.Option>
					</Select>
				</div>

				<p className="marginTop-12">
					{selectedLevel === 1
						? 'As written in these rules. The aging card Very Stupid is removed from the game. Start with 18 Robinson starting cards and 20 life points plus 2 more life points in the reserve.'
						: selectedLevel === 2
						? 'Like Level 1. In addition you draw a face-down aging card and shuffle it together with the 18 Robinson starting cards (do not look at the aging card!).'
						: selectedLevel === 3
						? 'Like Level 2. Before performing all the steps above, shuffle the Very Stupid card together with the other aging cards.'
						: selectedLevel === 4
						? 'Like Level 3. Start with only 18 life points plus 2 life points in the reserve. This is the real game!'
						: ''}
				</p>
			</div>
		</Modal>
	);
};

StartUpModal.propTypes = {
	className: PropTypes.string,
	showTheStartUpModal: PropTypes.bool,
	callback: PropTypes.func,
};

export default StartUpModal;
