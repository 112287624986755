/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { validateObject } from '../../util';
import { Button, Popover } from 'antd';
import cards from '../../cards.json';
import './card.css';

import lifePoint from '../../images/life_point.png';
import fence from '../../images/fence.png';

// hazard card images
import cannibals from '../../images/cannibals.png';
import furtherExploring from '../../images/further_exploring.png';
import exploring from '../../images/exploring.png';
import wildAnimals from '../../images/wildAnimals.png';
import wreck from '../../images/wreck.png';

import neutral from '../../images/neu.png';
import positive from '../../images/pos.png';
import negative from '../../images/neg.png';
import aging from '../../images/age.png';

const Card = (props) => {
	const images = {
		1: wildAnimals,
		2: cannibals,
		3: wreck,
		4: exploring,
		5: furtherExploring,
		6: neutral,
		7: positive,
		8: negative,
		9: aging,
	};

	const specialAbility = props.specialAbility ? props.specialAbility : '...';

	useEffect(() => {
		if (props.onRender) {
			props.onRender();
		}
	}, []);

	const handleClickOnCard = (e) => {
		if (props.onClickCallback) {
			props.onClickCallback(e);
		}
	};

	const renderSpecialAbility = () => {
		if (props.hazard) {
			return (
				<div
					style={{
						position: 'absolute',
						bottom: '5px',
						width: '190px',
					}}
				>
					<div className="d:f" style={{ justifyContent: 'center' }}>
						<div className="card-content-front-specialAbility t-a:c">
							<p>{specialAbility}</p>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div
				style={{
					position: 'absolute',
					bottom: '110px',
					width: '190px',
				}}
			>
				<div className="d:f" style={{ justifyContent: 'center' }}>
					<div className="card-content-front-specialAbility">
						<p>{specialAbility}</p>
					</div>
				</div>
			</div>
		);
	};

	const isAgingCard = (card) => {
		if (card) {
			return (
				'superAged' in cards[card] ||
				card.includes('a-') ||
				card.includes('sa-')
			);
		}

		return false;
	};

	const getFirstHalfStyles = () => {
		return {
			backgroundImage:
				"url('" +
				images[
					isAgingCard(props.id)
						? 9
						: props.fightValue === 0
						? 6
						: props.fightValue > 0
						? 7
						: 8
				] +
				"')",
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
		};
	};

	let style = props.style;

	if (props.showHazard) {
		style = { ...props.style, transform: 'rotate(180deg)' };
	}

	return (
		<div className="card-container">
			<div
				key-id={props.key_id ? props.key_id : ''}
				className={`p:r o:h card${
					props.className ? ` ${props.className}` : ''
				}${props.onClickCallback ? ' c:p' : ''}`}
				onClick={handleClickOnCard}
				style={style}
				id={props.id ? props.id : ''}
			>
				{validateObject(props, ['tags'], []).length > 0 ? (
					<div
						style={{
							position: 'relative',
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<Popover
							title="Additional info:"
							trigger="hover"
							content={props.tags.join(', ')}
							defaultVisible={false}
						>
							<Button
								type="primary"
								shape="circle"
								size="small"
								style={{
									position: 'absolute',
									zIndex: 10,
									top: '-10px',
								}}
							>
								|
							</Button>
						</Popover>
					</div>
				) : (
					<></>
				)}
				{props.front ? (
					<div
						className="card-content-front"
						style={{
							backgroundImage: 'url(' + import('../../images/forest.png') + ')',
						}}
					>
						<div
							className="card-content-front-first-half"
							style={getFirstHalfStyles()}
						>
							<div className="row">
								<div
									className="col-xs-4 d:f"
									style={{ justifyContent: 'center' }}
								>
									<div className="card-content-front-fightPoints">
										{props.fightValue}
									</div>
								</div>
								<div
									className="col-xs-4 d:f"
									style={{ justifyContent: 'center' }}
								>
									<div className="card-content-front-title t-a:c">
										{props.state}
									</div>
								</div>
								<div
									className="col-xs-4 d:f"
									style={{ justifyContent: 'center' }}
								>
									<div className="card-content-front-lifePoints">
										<img
											alt="life_point"
											width="25px"
											height="25px"
											src={lifePoint}
										/>
										{props.lifePoints === 2 && (
											<img
												alt="tmp"
												width="25px"
												height="25px"
												src={lifePoint}
												style={{
													marginLeft: '-15px',
												}}
											/>
										)}
									</div>
								</div>
							</div>
							{props.hazard && renderSpecialAbility()}
						</div>
						{!props.hazard && (
							<>
								{renderSpecialAbility()}
								<div className="card-content-front-fence">
									<img src={fence} alt="fence" />
								</div>
							</>
						)}
						{props.hazard && (
							<div
								className="card-content-front-second-half"
								style={{
									backgroundImage: "url('" + images[props.imageNum] + "')",
								}}
							>
								<div className="row">
									<div className="col-xs-8 col-xs-offset-2">
										<div className="card-content-front-hazard t-a:c">
											{props.hazardDescription}
										</div>
									</div>
								</div>
								<div className="row" style={{ marginTop: '8px' }}>
									<div
										className="col-xs-4 col-xs-offset-7"
										style={{ display: 'flex', justifyContent: 'flex-end' }}
									>
										<div className="card-content-front-highHazardValue">
											{props.diffcultLevel}
										</div>
									</div>
								</div>
								<div className="row" style={{ marginTop: '8px' }}>
									<div
										className="col-xs-4 col-xs-offset-7"
										style={{ display: 'flex', justifyContent: 'flex-end' }}
									>
										<div className="card-content-front-mediumHazardValue">
											{props.mediumLevel}
										</div>
									</div>
								</div>
								<div className="row" style={{ marginTop: '8px' }}>
									<div
										className="col-xs-4"
										style={{ display: 'flex', justifyContent: 'center' }}
									>
										<div className="card-content-front-freeCards">
											{props.freeCards}
										</div>
									</div>
									<div
										className="col-xs-4 col-xs-offset-3"
										style={{ display: 'flex', justifyContent: 'flex-end' }}
									>
										<div className="card-content-front-lowHazardValue">
											{props.easyLevel}
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				) : (
					<div className="card-content-back d:f">
						<p className="card-content-back-title">Friday</p>
					</div>
				)}
			</div>
		</div>
	);
};

// Specifies the default values for props:
Card.defaultProps = {
	className: '',
	tags: [],
};

// id is necessary for the proper display of the images on the cards
Card.propTypes = {
	className: PropTypes.string,
	diffcultLevel: PropTypes.number,
	easyLevel: PropTypes.number,
	fightValue: PropTypes.number,
	freeCards: PropTypes.number,
	front: PropTypes.bool,
	hazard: PropTypes.bool,
	hazardDescription: PropTypes.string,
	id: PropTypes.string,
	imageNum: PropTypes.number,
	lifePoints: PropTypes.number,
	mediumLevel: PropTypes.number,
	onClickCallback: PropTypes.func,
	onRender: PropTypes.func,
	showHazard: PropTypes.bool,
	specialAbility: PropTypes.string,
	state: PropTypes.string,
	style: PropTypes.object,
	tags: PropTypes.array,
	key_id: PropTypes.string,
};

export default Card;
