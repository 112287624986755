/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import Card from '../card/card';
import cards from '../../cards.json';
import './resolveModal.css';

const ResolveModal = (props) => {
	const [totalLostLifePoints, setTotalLostLifePoints] = useState(0);
	const [KPS_cardsToRemove, setKPS_cardsToRemove] = useState([]);
	const [cardsToResolve, setCardsToResolve] = useState([]);
	const [showTheResolveModal, setShowTheResolveModal] = useState(false);
	const [
		additionalLifeToRemoveFromAgingPower,
		setAdditionalLifeToRemoveFromAgingPower,
	] = useState(0);

	const showResolveModalRef = useRef(showTheResolveModal);
	const _setShowResolveModal = (data) => {
		showResolveModalRef.current = data;
		setShowTheResolveModal(data);
	};

	const KPS_cardsToRemoveRef = useRef([]);
	const _setKPS_cardsToRemove = (data) => {
		KPS_cardsToRemoveRef.current = data;
		setKPS_cardsToRemove(data);
	};

	const cardsToResolveRef = useRef([]);
	const _setCardsToResolve = (data) => {
		cardsToResolveRef.current = data;
		setCardsToResolve(data);
	};

	useLayoutEffect(() => {
		_setShowResolveModal(props.showTheResolveModal);
	}, [props.showTheResolveModal]);

	useLayoutEffect(() => {
		if (props.showTheResolveModal) {
			const cardsToResolve = props.cardsToResolve(true);
			let totalLostLifePoints = 0;

			cardsToResolve.forEach((c) => {
				if (
					'subtractLife' in cards[c] &&
					!props.cardsAlreadyDestroyed.includes(c)
				) {
					totalLostLifePoints += cards[c].subtractLife;
				}
			});

			setAdditionalLifeToRemoveFromAgingPower(totalLostLifePoints);

			_setCardsToResolve(cardsToResolve);
		}
	}, [props.cardsToResolve, props.showTheResolveModal]);

	const onKeyPress = (e) => {
		if ((e.ctrlKey || e.shiftKey) && showResolveModalRef.current) {
			if (e.key.toLowerCase() === 'u') {
				e.preventDefault();

				let isAdd = true;
				const tmpRemovableList = cardsToResolveRef.current.filter((card) => {
					if ('superAged' in cards[card]) {
						return false;
					}

					const c = cards[card];

					if (isAdd && KPS_cardsToRemoveRef.current.includes(card)) {
						isAdd = false;
					}

					return !c.specialAbility && c.fightValue <= 0;
				});

				let tmpLifePoints = 0;

				tmpRemovableList.forEach((c) => {
					tmpLifePoints += cards[c].lifePoints;
				});

				if (isAdd) {
					_setKPS_cardsToRemove([...tmpRemovableList]);
					setTotalLostLifePoints(totalLostLifePoints + tmpLifePoints);
				} else {
					// TODO: remove logic needs to be done
					_setKPS_cardsToRemove([...tmpRemovableList]);
					setTotalLostLifePoints(totalLostLifePoints - tmpLifePoints);
				}
			}
		}

		// if (e.key === 'Enter') {
		// 	removeAllSelectedCards();
		// }
	};

	useLayoutEffect(() => {
		const tmpFn = (e) => onKeyPress(e);

		document.addEventListener('keypress', tmpFn);

		return () => {
			document.removeEventListener('keypress', tmpFn);
		};
	}, []);

	const selectCard = (selectedCard) => {
		if (KPS_cardsToRemove.includes(selectedCard)) {
			_setKPS_cardsToRemove(
				KPS_cardsToRemove.filter((c) => c !== selectedCard)
			);
			setTotalLostLifePoints(
				totalLostLifePoints - cards[selectedCard].lifePoints
			);
		} else {
			_setKPS_cardsToRemove([...KPS_cardsToRemove, selectedCard]);
			setTotalLostLifePoints(
				totalLostLifePoints + cards[selectedCard].lifePoints
			);
		}
	};

	const removeAllSelectedCards = () => {
		if (props.onOk) {
			props.onOk(
				(totalLostLifePoints > props.differenceInPoints
					? totalLostLifePoints -
					  props.differenceInPoints +
					  props.differenceInPoints
					: props.differenceInPoints) + additionalLifeToRemoveFromAgingPower,
				KPS_cardsToRemove
			);
		}
	};

	const cardsToRender = (cardsToResolve) => {
		if (cardsToResolve && cards) {
			return cardsToResolve
				.filter((card) => !props.cardsAlreadyDestroyed.includes(card))
				.sort((a, b) => cards[a].fightValue - cards[b].fightValue);
		}

		return [];
	};

	const resetState = () => {
		_setKPS_cardsToRemove([]);
		setTotalLostLifePoints(0);
	};

	const wordifyNumbers = (num) => {
		if (num === 0) {
			return 'zero';
		} else if (num === 1) {
			return 'one';
		} else if (num === 2) {
			return 'two';
		} else if (num === 3) {
			return 'three';
		} else if (num === 4) {
			return 'four';
		} else {
			return 'n/a';
		}
	};

	let message = ', ';

	if (additionalLifeToRemoveFromAgingPower > 0) {
		message = ` and an additional ${wordifyNumbers(
			additionalLifeToRemoveFromAgingPower
		)} life point(s) from the aging cards, `;
	}

	return (
		<Modal
			className={`resolveModal${props.className ? ` ${props.className}` : ''}`}
			visible={props.showTheResolveModal}
			closable={false}
			afterClose={resetState}
			destroyOnClose
			footer={[
				<Button onClick={removeAllSelectedCards} autoFocus type="primary">
					{KPS_cardsToRemove.length === 0 ? 'Ok' : 'Remove all selected cards'}
				</Button>,
			]}
			width="100%"
		>
			{props.didWin ? (
				<h2 className="resolveModal-won t-a:c">You won the fight!</h2>
			) : (
				<h2 className="resolveModal-lost t-a:c">Sorry, you lost the fight!</h2>
			)}

			<p className="t-a:c">
				<i>
					<b>Note</b>: You will lose <b>{props.differenceInPoints}</b> life
					point(s){message}
					so for free (excluding the additionally lost life points), you can{' '}
					<i>discard</i> (remove from the game) the fight cards based on the
					specified life points on the card. However, after the <i>free</i>{' '}
					ones, you have to pay the number of life points on the fight card to
					discard it. So make your choice wisely by clicking on the cards below!
				</i>
			</p>

			<div className="resolveModal-cards t-a:c">
				{cardsToRender(cardsToResolve).map((card) => (
					<div
						className={`resolveModal-cards-card d:i-flex marginLeft-24 marginTop-24`}
						key={card}
					>
						{card.startsWith('h-') ? (
							<>
								<Card
									id={card}
									className={KPS_cardsToRemove.includes(card) ? 'remove' : ''}
									front
									diffcultLevel={cards[card].levels.hard}
									mediumLevel={cards[card].levels.medium}
									easyLevel={cards[card].levels.easy}
									hazardDescription={cards[card].hazard}
									freeCards={cards[card].freeCards}
									imageNum={cards[card].imageNum}
									hazard
									lifePoints={cards[card].lifePoints}
									fightValue={cards[card].fightValue}
									state={cards[card].state}
									specialAbility={cards[card].specialAbility}
									onClickCallback={() => {
										selectCard(card);
									}}
								/>
							</>
						) : (
							<>
								<Card
									id={card}
									className={KPS_cardsToRemove.includes(card) ? 'remove' : ''}
									front
									lifePoints={cards[card].lifePoints}
									fightValue={cards[card].fightValue}
									state={cards[card].state}
									specialAbility={cards[card].specialAbility}
									onClickCallback={() => {
										selectCard(card);
									}}
								/>
							</>
						)}
					</div>
				))}
			</div>

			<div className="t-a:c marginTop-24">
				<p className="t-a:c" style={{ fontSize: '16px' }}>
					You will lose{' '}
					<b>
						{(totalLostLifePoints > props.differenceInPoints
							? totalLostLifePoints -
							  props.differenceInPoints +
							  props.differenceInPoints
							: props.differenceInPoints) +
							additionalLifeToRemoveFromAgingPower}
					</b>{' '}
					life points!
				</p>
			</div>
		</Modal>
	);
};

ResolveModal.propTypes = {
	className: PropTypes.string,
	showTheResolveModal: PropTypes.bool,
	onOk: PropTypes.func,
	didWin: PropTypes.bool,
	cardsToResolve: PropTypes.func,
	differenceInPoints: PropTypes.number,
	cardsAlreadyDestroyed: PropTypes.array,
};

export default ResolveModal;
