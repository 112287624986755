/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './pirateCard.css';

import pirateShip from '../../images/pirateShip.png';

const PirateCard = (props) => {
	useEffect(() => {
		if (props.onRender) {
			props.onRender();
		}
	}, []);

	const handleClickOnCard = (e) => {
		if (props.onClickCallback) {
			props.onClickCallback(e);
		}
	};

	let style = props.style;

	if (props.showHazard) {
		style = { ...props.style, transform: 'rotate(180deg)' };
	}

	return (
		<div
			className={`p:r o:h fCenter pirateCard${
				props.className ? ` ${props.className}` : ''
			}${props.onClickCallback ? ' c:p' : ''}`}
			onClick={handleClickOnCard}
			style={style}
			id={props.id ? props.id : ''}
		>
			<div className={`pirateCard-inside`}>
				<div className="row">
					<div className="col-xs-6">
						<div className="pirateCard-inside-freeCards fCenter">
							{props.freeCards > 0 ? props.freeCards : '*'}
						</div>
					</div>
					<div className="col-xs-6">
						<div className="pirateCard-inside-requiredFightScore fCenter">
							{props.fightValue > 0 ? props.fightValue : '*'}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xs-12 fCenter">
						<img
							alt="Pirate ship"
							src={pirateShip}
							width="90px"
							height="80px"
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-xs-12">
						<div className="pirateCard-inside-description fCenter">
							<p>{props.description}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

PirateCard.propTypes = {
	className: PropTypes.string,
	fightValue: PropTypes.number,
	freeCards: PropTypes.number,
	description: PropTypes.string,
	id: PropTypes.string,
	onClickCallback: PropTypes.func,
	onRender: PropTypes.func,
	style: PropTypes.object,
};

export default PirateCard;
