/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState, useRef } from 'react';
import { authenticator, totp } from 'otplib';
import './App.css';
import Card from './components/card/card';
import ResolveModal from './components/resolveModal/resolveModal';
import ResolveDouble from './components/resolveDouble/resolveDouble';
import Resolve1xbelow from './components/resolve1xbelow/resolve1xbelow';
import ResolveDestroy from './components/resolveDestroy/resolveDestroy';
import ResolveSort3 from './components/resolveSort3/resolveSort3';
import ResolveCopy from './components/resolveCopy/resolveCopy';
import ResolveExchange from './components/resolveExchange/resolveExchange';
import StartUpModal from './components/startupModal/startupModal';
import NavBar from './components/navbar/navbar';
import PirateCard from './components/pirateCard/pirateCard';
import Help from './components/help/help';
import { shuffle, validateObject, numPadding } from './util';
import cards from './cards.json';
import {
	Button,
	Descriptions,
	message,
	Modal,
	notification,
	Popconfirm,
	Tooltip,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ScoreBreakdown from './components/scoreBreakdown/scoreBreakdown';
import axios from 'axios';

import milestoneIcon from './images/milestone.png';
import trophyIcon from './images/trophy.png';
import { useEffect } from 'react/cjs/react.development';

function App() {
	const defaultGameMode = 1;
	const superAgingCards = ['sa-1', 'sa-2', 'sa-3'];
	const agingCards = ['a-1', 'a-2', 'a-3', 'a-4', 'a-5', 'a-6', 'a-7'];
	const difficultAgingCard = 'a-8';
	// values in the array are the acutal keys of the cards in the cards.json file
	const cardsThatCanBeExcludedOnDoubleAndPhase1 = ['addCard', 'exchange'];
	const timedModeMap = [0, 5, 7, 9, 12, 15]; // first index (0) is not used
	const pirateCards = [
		'p-1',
		'p-2',
		'p-3',
		'p-4',
		'p-5',
		'p-6',
		'p-7',
		'p-8',
		'p-9',
		'p-10',
	];

	const [robinsonDeck, setRobinsonDeck] = useState([
		'rc-1',
		'rc-2',
		'rc-3',
		'rc-4',
		'rc-5',
		'rc-6',
		'rc-7',
		'rc-8',
		'rc-9',
		'rc-10',
		'rc-11',
		'rc-12',
		'rc-13',
		'rc-14',
		'rc-15',
		'rc-16',
		'rc-17',
		'rc-18',
	]);

	const robinsonDeckRef = useRef(robinsonDeck);
	const _setRobinsonDeck = (data) => {
		robinsonDeckRef.current = data;
		setRobinsonDeck(data);
	};

	const [hazardDeck, setHazardDeck] = useState([
		'h-1',
		'h-2',
		'h-3',
		'h-4',
		'h-5',
		'h-6',
		'h-7',
		'h-8',
		'h-9',
		'h-10',
		'h-11',
		'h-12',
		'h-13',
		'h-14',
		'h-15',
		'h-16',
		'h-17',
		'h-18',
		'h-19',
		'h-20',
		'h-21',
		'h-22',
		'h-23',
		'h-24',
		'h-25',
		'h-26',
		'h-27',
		'h-28',
		'h-29',
		'h-30',
	]);

	const [gameLevel, setGameLevel] = useState(1);
	const [name, setName] = useState('');
	const [score, setScore] = useState(0);
	const [showStartupModal, setShowStartupModal] = useState(false);

	const [agingDeck, setAgingDeck] = useState([]);

	const [selectedRobinsonCards, setSelectedRobinsonCards] = useState([]);
	const [selectedHazardCards, setSelectedHazardCards] = useState([]);

	const [hazardDiscardPile, setHazardDiscardPile] = useState([]);
	const [robinsonDiscardPile, setRobinsonDiscardPile] = useState([]);

	const [selectedHazard, setSelectedHazard] = useState('');

	const [hazardSelectionModal, setHazardSelectionModal] = useState(false);

	const [isFightOn, setIsFightOn] = useState(false);

	const [lifeInReserve, setLifeInReserve] = useState(2);
	const [lifeInHand, setLifeInHand] = useState(20);
	const [pauseOption, setPauseOption] = useState(false);

	const [gameMode, setGameMode] = useState(defaultGameMode);
	const [currentFightScore, setCurrentFightScore] = useState(0);
	const [freeCardsLimit, setFreeCardsLimit] = useState(0);
	const [isBaseGameComplete, setIsBaseGameComplete] = useState(false);

	const [additionalCardsDrawn, setAdditionalCardsDrawn] = useState([]);

	const [showResolveModal, setShowResolveModal] = useState(false);

	const [eliminatedCards, setEliminatedCards] = useState([]);

	const [cardsAlreadyDestroyed, setCardsAlreadyDestroyed] = useState([]);

	const [stopped, setStopped] = useState(false);
	const [highestCardIsZero, setHighestCardIsZero] = useState(false);
	const [highestCardIsZeroCard, setHighestCardIsZeroCard] = useState('');
	const [cardsAlreadyDoubled, setCardsAlreadyDoubled] = useState([]);
	const [showTheResolveDoubleModal, setShowTheResolveDoubleModal] =
		useState(false);

	const [stopDrawingFightCards, setStopDrawingFightCards] = useState(false);

	const [showTheResolve1xbelowModal, setShowTheResolve1xbelowModal] =
		useState(false);

	const [showTheResolveDestroyModal, setShowTheResolveDestroyModal] =
		useState(false);

	const [cardsToSkip1xbelowResolve, setCardsToSkip1xbelowResolve] = useState(
		[]
	);

	const [cardsToSkipDestroyResolve, setCardsToSkipDestroyResolve] = useState(
		[]
	);

	const [cardsExplicitlyDestroyed, setCardsExplicitlyDestroyed] = useState([]);

	const [tempBarFromDoublingTheOwnCard, setTempBarFromDoublingTheOwnCard] =
		useState('');

	const [fightingPirates, setFightingPirates] = useState(false);
	const [reducedGameMode, setReducedGameMode] = useState(0);

	const [showTheResolveSort3Modal, setShowTheResolveSort3Modal] =
		useState(false);

	const [top3CardsFromRobinsonDeck, setTop3CardsFromRobinsonDeck] = useState(
		[]
	);

	const [showTheResolveExchange, setShowTheResolveExchange] = useState(false);
	const [skipTheCardToExchange, setSkipTheCardToExchange] = useState('');
	const [numberOfCardsToExchange, setNumberOfCardsToExchange] = useState(0);

	const [isCopyCardActive, setIsCopyCardActive] = useState(false);
	const [
		consecutiveStepInCopyWithCopyCard,
		setConsecutiveStepInCopyWithCopyCard,
	] = useState('');

	const [showTheResolveCopy, setShowTheResolveCopy] = useState(false);
	const [skipTheCardsToCopy, setSkipTheCardsToCopy] = useState([]);
	const [cardsAlreadyCopied, setCardsAlreadyCopied] = useState([]);

	const [selectedSpecialAbilityCard, setSelectedSpecialAbilityCard] =
		useState('');

	const [randomPirateCards, setRandomPirateCards] = useState([]);
	const [lostPirates, setLostPirates] = useState([]);
	const [wonPirates, setWonPirates] = useState([]);

	const [gameOver, setGameOver] = useState(false);

	const [showGameOverModal, setShowGameOverModal] = useState(false);

	const [
		onlyHalfTheCardsCountBeingFought,
		setOnlyHalfTheCardsCountBeingFought,
	] = useState(false);

	const [gameModeTempState, setGameModeTempState] = useState(defaultGameMode);

	const [clockWorker, setClockWorker] = useState('');
	const [backendHeartbeatWorker, setBackendHeartbeatWorker] = useState('');

	const [timedMode, setTimedMode] = useState(0);

	const [remainingTime, setRemianingTime] = useState(0);

	const [remainingTimeToShow, setRemianingTimeToShow] = useState('');

	const [gameStartTime, setGameStartTime] = useState(new Date());

	const [showHelpModal, setShowHelpModal] = useState(false);

	const [listOfAvailableSpeicalAbilities, setListOfAvailableSpeicalAbilities] =
		useState([]);

	const [showSpecialAbilitiesTrail, setShowSpecialAbilitiesTrail] =
		useState(true);

	const [showScoreBreakdownModal, setShowScoreBreakdownModal] = useState(false);

	const [outOfAgingCards, setOutOfAgingCards] = useState(false);
	const [alreadySelectedCards, setAlreadySelectedCards] = useState([]);

	const [totalGameTime, setTotalGameTime] = useState({
		totalSecs: 0,
		str: '0 min 0 sec',
		minStr: '0:0',
	});

	const [totalFightsWon, setTotalFightsWon] = useState(0);
	const [totalFights, setTotalFights] = useState(0);

	const [gameID, setGameID] = useState('');
	const [showNetworkIssueStatus, setShowNetworkIssueStatus] = useState(false);
	const [initialRequestFailed, setInitialRequestFailed] = useState(false);

	const remainingAgingCardsAddsUpValRef = useRef(0);

	const wonTheGameRef = useRef(false);

	const showScoreBreakdownModalRef = useRef(showScoreBreakdownModal);
	const _setShowScoreBreakdownModal = (data) => {
		showScoreBreakdownModalRef.current = data;
		setShowScoreBreakdownModal(data);
	};

	const showSpecialAbilitiesTrailRef = useRef(showSpecialAbilitiesTrail);
	const _setShowSpecialAbilitiesTrail = (data) => {
		showSpecialAbilitiesTrailRef.current = data;
		setShowSpecialAbilitiesTrail(data);
	};

	const hazardSelectionModalRef = useRef(hazardSelectionModal);
	const _setHazardSelectionModal = (data) => {
		hazardSelectionModalRef.current = data;
		setHazardSelectionModal(data);
	};

	const isFightOnRef = useRef(isFightOn);
	const _setIsFightOn = (data) => {
		isFightOnRef.current = data;
		setIsFightOn(data);
	};

	const selectedHazardRef = useRef(selectedHazard);
	const _setSelectedHazard = (data) => {
		selectedHazardRef.current = data;
		setSelectedHazard(data);
	};

	const agingDeckRef = useRef(agingDeck);
	const _setAgingDeck = (data) => {
		agingDeckRef.current = data;
		setAgingDeck(data);
	};

	const hazardDeckRef = useRef(hazardDeck);
	const _setHazardDeck = (data) => {
		hazardDeckRef.current = data;
		setHazardDeck(data);
	};

	const robinsonDiscardPileRef = useRef(robinsonDiscardPile);
	const _setRobinsonDiscardPile = (data) => {
		robinsonDiscardPileRef.current = data;
		setRobinsonDiscardPile(data);
	};

	const hazardDiscardPileRef = useRef(hazardDiscardPile);
	const _setHazardDiscardPile = (data) => {
		hazardDiscardPileRef.current = data;
		setHazardDiscardPile(data);
	};

	const gameModeRef = useRef(gameMode);
	const _setGameMode = (data) => {
		gameModeRef.current = data;
		setGameMode(data);
	};

	const selectedHazardCardsRef = useRef(selectedHazardCards);
	const _setSelectedHazardCards = (data) => {
		selectedHazardCardsRef.current = data;
		setSelectedHazardCards(data);
	};

	const showResolveModalRef = useRef(showResolveModal);
	const _setShowResolveModal = (data) => {
		showResolveModalRef.current = data;
		setShowResolveModal(data);
	};

	const showGameoverModalRef = useRef(showGameOverModal);
	const _setShowGameOverModal = (data) => {
		showGameoverModalRef.current = data;
		setShowGameOverModal(data);
	};

	const showHelpModalRef = useRef(showHelpModal);
	const _setShowHelpModal = (data) => {
		showHelpModalRef.current = data;
		setShowHelpModal(data);
	};

	const showStartupModalRef = useRef(showStartupModal);
	const _setShowStartupModal = (data) => {
		showStartupModalRef.current = data;
		setShowStartupModal(data);
	};

	const isBaseGameCompleteRef = useRef(isBaseGameComplete);
	const _setIsBaseGameComplete = (data) => {
		isBaseGameCompleteRef.current = data;
		setIsBaseGameComplete(data);
	};

	const pauseOptionRef = useRef(pauseOption);
	const _setPauseOption = (data) => {
		pauseOptionRef.current = data;
		setPauseOption(data);
	};

	const hazardCardTogglerCounter = useRef(0);

	const onKeyPress = (e) => {
		if (showStartupModalRef.current || pauseOptionRef.current) {
			return;
		}

		if (e.ctrlKey || e.shiftKey) {
			if (e.key.toLowerCase() === 'h') {
				if (showGameoverModalRef.current) {
					return;
				}

				e.preventDefault();

				if (hazardDeckRef.current.length > 0) {
					const elem = document.querySelector('[key-id="hazardDeck"]');
					if (elem) {
						elem.click();
					}
				} else {
					notification['warning']({
						message: 'Hazard deck',
						description:
							'Would you please reshuffle the hazard deck before continuing to select the hazard?',
					});

					return;
				}
			}

			if (e.key.toLowerCase() === '?' || e.key.toLowerCase() === '/') {
				if (showGameoverModalRef.current) {
					return;
				}

				e.preventDefault();

				_setShowHelpModal(!showHelpModalRef.current);
			}

			if (e.key.toLowerCase() === 'n' && !isFightOnRef.current) {
				if (!isBaseGameCompleteRef.current) {
					notification['warning']({
						message: 'Pirates',
						description:
							'Sorry, you cannot play the pirates unless the base game (easy, medium, and hard modes) is complete.',
					});

					return;
				}

				const elem = document.querySelectorAll('[id^=p-]')[0];
				if (elem) {
					elem.click();
				}
			}

			if (e.key.toLowerCase() === 'm' && !isFightOnRef.current) {
				if (!isBaseGameCompleteRef.current) {
					notification['warning']({
						message: 'Pirates',
						description:
							'Sorry, you cannot play the pirates unless the base game (easy, medium, and hard modes) is complete.',
					});

					return;
				}

				const elem = document.querySelectorAll('[id^=p-]')[1];
				if (elem) {
					elem.click();
				}
			}

			if (e.key.toLowerCase() === 'b') {
				if (showGameoverModalRef.current) {
					return;
				}

				e.preventDefault();

				_setShowSpecialAbilitiesTrail(!showSpecialAbilitiesTrailRef.current);
			}

			if (e.key.toLowerCase() === 'v') {
				e.preventDefault();

				if (showGameoverModalRef.current && wonTheGameRef.current) {
					_setShowScoreBreakdownModal(!showScoreBreakdownModalRef.current);
				}
			}

			if (
				e.key.toLowerCase() === 'r' &&
				isFightOnRef.current &&
				!showResolveModalRef.current
			) {
				if (showGameoverModalRef.current) {
					return;
				}

				e.preventDefault();

				const elem = document.querySelector('.KPS-resolveFight');
				if (elem) {
					elem.click();
				}
			}

			if (e.key.toLowerCase() === 'f' && isFightOnRef.current) {
				if (showGameoverModalRef.current || showResolveModalRef.current) {
					return;
				}

				e.preventDefault();

				if (robinsonDeckRef.current.length > 0) {
					const elem = document.querySelector('[key-id="fightDeck"]');
					if (elem) {
						elem.click();
					}
				} else {
					notification['warning']({
						message: 'Robinson deck',
						description:
							'Would you please reshuffle the deck before continuing to fight the hazard?',
					});

					return;
				}
			}

			if (e.key.toLowerCase() === 'w') {
				if (showGameoverModalRef.current) {
					return;
				}

				e.preventDefault();

				if (hazardSelectionModalRef.current) {
					const elem = document.querySelector(
						`[key-id="hazardCard_selection_${
							hazardCardTogglerCounter.current % 2
						}"]`
					);
					if (elem) {
						elem.click();
					}

					hazardCardTogglerCounter.current++;
				}
			}

			// shuffle hazard discard pile
			if (e.key.toLowerCase() === 'k') {
				if (showGameoverModalRef.current) {
					return;
				}

				e.preventDefault();

				shuffleHazardCards(hazardDiscardPileRef.current, gameModeRef.current);
			}

			// shuffle robinson discard pile + aging card
			if (e.key.toLowerCase() === 'l') {
				if (showGameoverModalRef.current) {
					return;
				}

				e.preventDefault();

				shuffleRobinsonCardsWithAnAgingCard(
					robinsonDeckRef.current,
					agingDeckRef.current,
					robinsonDiscardPileRef.current
				);
			}
		}

		if (e.key === 'Enter') {
			if (hazardSelectionModalRef.current && selectedHazardRef.current) {
				if (showGameoverModalRef.current) {
					return;
				}

				e.preventDefault();

				hazardCardTogglerCounter.current = 0;
				hazardSelectionOnOkay(
					selectedHazardRef.current,
					hazardDiscardPileRef.current,
					selectedHazardCardsRef.current
				);
			}
		}

		return false;
	};

	const getToken = () => {
		totp.options = { algorithm: 'sha1', digits: 6, time: 30 };
		return authenticator.generate(process.env.REACT_APP_PASSEDOC);
	};

	useLayoutEffect(() => {
		wonTheGameRef.current =
			isBaseGameCompleteRef.current && wonPirates.length === 2;
	}, [wonPirates]);

	useLayoutEffect(() => {
		const tmpFn = (e) => {
			return onKeyPress(e);
		};

		let tmpWorker = new Worker('backendHeartbeatWorker.js');
		let URL = process.env.REACT_APP_BACKEND_URL + '/status';
		setBackendHeartbeatWorker(tmpWorker);

		tmpWorker.postMessage({
			URL,
			performHeartbeat: false,
		});

		tmpWorker.onmessage = (e) => {
			setShowNetworkIssueStatus(e.data);
		};

		document.addEventListener('keypress', (e) => tmpFn(e));

		return () => {
			document.removeEventListener('keypress', (e) => tmpFn(e));
		};
	}, []);

	useLayoutEffect(() => {
		_setShowStartupModal(true);

		const shuffledRobinsonCards = shuffle(robinsonDeck);
		_setRobinsonDeck(shuffledRobinsonCards.filter((c) => c));

		const shuffledHazardCards = shuffle(hazardDeck);
		_setHazardDeck(shuffledHazardCards);

		let regularAgingCards = agingCards;

		_setAgingDeck([...shuffle(superAgingCards), ...shuffle(regularAgingCards)]);

		const shuffledPirateCards = shuffle(pirateCards);

		let tmpSelectedPirateCards = [shuffledPirateCards.pop()];
		tmpSelectedPirateCards.push(shuffledPirateCards.pop());

		const p1 = localStorage.getItem("p1");
		const p2 = localStorage.getItem("p2");

		if (p1 && p2) {
			setRandomPirateCards([p1, p2]);
		} else {
			setRandomPirateCards(tmpSelectedPirateCards);
		}
	}, []);

	useLayoutEffect(() => {
		let freeCards = 0;

		if (isPirateCard(selectedHazard)) {
			if ('fightAgainstAllRemainingHazardCards' in cards[selectedHazard]) {
				[...hazardDeck, ...hazardDiscardPile].forEach((a) => {
					freeCards += cards[a].freeCards;
				});
			} else {
				freeCards = validateObject(
					cards,
					[selectedHazard, 'numberOfFreeCards'],
					0
				);
			}
		} else {
			freeCards = validateObject(cards, [selectedHazard, 'freeCards'], 0);
		}

		setFreeCardsLimit(freeCards);
	}, [selectedHazard, hazardDeck, hazardDiscardPile]);

	// useLayoutEffect(() => {
	// 	// to get rid once the dev is complete
	// 	const cardsToResolve = getCardsToResolve();

	// 	const vitals = {
	// 		name: name,
	// 		lifeInHand: lifeInHand,
	// 		lifeInReserve: lifeInReserve,
	// 		currentFightScore: `${currentFightScore}/${getFightScoreForTheMode(
	// 			selectedHazard
	// 		)} (${freeCardsLimit - selectedRobinsonCards.length})`,
	// 		gameMode: gameMode,
	// 		robinsonDeck: `${robinsonDeck.length} cards | ${robinsonDeck.join(',')}`,
	// 		remainingAgingCards: agingDeck.length,
	// 		currentHazardBeingFought: selectedHazard,
	// 		hazardDeck: `${hazardDeck.length} cards | ${hazardDeck.join(',')}`,
	// 		fightCards: `${cardsToResolve.length} cards | ${cardsToResolve.join(
	// 			','
	// 		)}`,
	// 		overallGameScore: score,
	// 		wonPirates: wonPirates,
	// 		lostPirates: lostPirates,
	// 		isBaseGameCompleteRef: isBaseGameCompleteRef,
	// 		fightingPirates: fightingPirates,
	// 		highestCardIsZero: highestCardIsZero,
	// 		highestCardIsZeroCard: highestCardIsZeroCard,
	// 	};

	// 	if (window.message) {
	// 		vitals.message = window.message;
	// 		window.message = '';
	// 	}

	// 	console.groupCollapsed('Vitals');
	// 	Object.keys(vitals).forEach((each) =>
	// 		console.log(`${each}: ${vitals[each]}`)
	// 	);
	// 	console.groupEnd();

	// 	axios({
	// 		method: 'post',
	// 		url: 'https://saturday.bl5.me/logVitals',
	// 		data: {
	// 			vitals: vitals,
	// 		},
	// 	})
	// 		.then((resp) => resp)
	// 		.catch((err) => console.log(err));
	// }, [
	// 	robinsonDeck,
	// 	wonPirates,
	// 	lostPirates,
	// 	isBaseGameCompleteRef,
	// 	highestCardIsZero,
	// 	highestCardIsZeroCard,
	// ]);

	useLayoutEffect(() => {
		// check if isBaseGameComplete is true and if wonPirates is equal to true then show the gameover modal
		if (isBaseGameCompleteRef.current && wonPirates.length === 2) {
			calculateGameScore();
		}
	}, [wonPirates]);

	useLayoutEffect(() => {
		if (
			isBaseGameCompleteRef.current &&
			wonPirates.length === 2 &&
			!showGameOverModal
		) {
			Modal.confirm({
				title: '🎊 You made it!!! 🥳',
				className: 'trophyModal',
				icon: (
					<img src={trophyIcon} className="trophyModalIcon" alt="trophy icon" />
				),
				content:
					'Congratulations! ✨ You successfully helped Robinson escape the island! 🏝️',
				okText: 'I won!!!',
				autoFocusButton: 'ok',
				cancelButtonProps: { style: { display: 'none' } },
				closable: true,
			});
		}
	}, [wonPirates]);

	useLayoutEffect(() => {
		if (
			isBaseGameCompleteRef.current &&
			wonPirates.length === 2 &&
			selectedHazardCards.length === 0 &&
			allHazardCardsAreDealt()
		) {
			calculateGameScore();
			_setShowGameOverModal(true);
		}
	});

	useLayoutEffect(() => {
		if (!showStartupModal) {
			setGameStartTime(new Date());
		}
	}, [showStartupModal]);

	useLayoutEffect(() => {
		if (
			gameMode === 3 &&
			hazardDeck.length === 0 &&
			selectedHazardCards.length === 0 &&
			selectedHazard === '' &&
			!isBaseGameCompleteRef.current &&
			!showGameOverModal
		) {
			_setIsBaseGameComplete(true);

			Modal.confirm({
				title: 'Woo hoo 🥳',
				className: 'milestoneModal',
				icon: (
					<img
						src={milestoneIcon}
						className="milestoneModalIcon"
						alt="milestone icon"
					/>
				),
				content:
					'You have successfully completed the base game! 🎉  Now you can start playing the Pirates! 🏴‍☠️',
				okText: "Yay! Let's rock...",
				autoFocusButton: 'ok',
				cancelButtonProps: { style: { display: 'none' } },
				closable: true,
			});
		}
	}, [gameMode, hazardDeck, selectedHazardCards, selectedHazard]);

	useLayoutEffect(() => {
		if (fightingPirates) {
			setGameModeTempState(gameMode);
			_setGameMode(4);
		} else {
			_setGameMode(gameModeTempState);
			setGameModeTempState(defaultGameMode);
		}
	}, [fightingPirates]);

	useLayoutEffect(() => {
		if (timedMode > 0) {
			// Starting a web clockWorker that checks if the token is already expired
			let tmpWorker = new Worker('clockWorker.js');
			setClockWorker(tmpWorker);

			tmpWorker.postMessage({
				timedModeKey: timedModeMap[timedMode] * 60,
				pauseOption,
			});

			tmpWorker.onmessage = (e) => {
				if (!e.data.pauseOption) {
					const tmpObj = computeRemainingTime(e.data);

					setRemianingTime(e.data);
					setRemianingTimeToShow(tmpObj.minStr);

					if (e.data === 0) {
						_setShowGameOverModal(true);
					}
				}
			};
		}
	}, [timedMode]);

	useLayoutEffect(() => {
		if (timedMode > 0 && clockWorker) {
			clockWorker.postMessage({ timedModeKey: -1, pauseOption });
		}
	}, [pauseOption, timedMode, clockWorker]);

	useLayoutEffect(() => {
		if (showGameOverModal && timedMode > 0) {
			clockWorker.terminate();
		}

		if (
			!showStartupModal &&
			showGameOverModal &&
			!initialRequestFailed &&
			totalGameTime.totalSecs > 0
		) {
			let agingCards = 0;
			let robinsonCards = 0;
			let robinsonCardPoints = 0;
			let robinsonCardsArr = [];

			[
				...selectedRobinsonCards,
				...additionalCardsDrawn,
				...robinsonDeck,
				...robinsonDiscardPile,
			].forEach((c) => {
				if (isAgingCard(c)) {
					agingCards++;
				} else {
					robinsonCards++;

					robinsonCardPoints += cards[c].fightValue;

					robinsonCardsArr.push(c);
				}
			});

			axios
				.put(
					process.env.REACT_APP_BACKEND_URL,
					{
						won: isBaseGameCompleteRef.current && wonPirates.length === 2,
						id: gameID,
						time_taken: parseInt(totalGameTime.totalSecs.toFixed(0)),
						fights_won: totalFightsWon,
						fights_lost: totalFights - totalFightsWon,
						total_robinson_cards: robinsonCards,
						total_robinson_card_points: robinsonCardPoints,
						life_points: lifeInHand,
						aging_cards: agingCards,
						hazard_cards: [
							...hazardDiscardPile,
							...hazardDeck,
							isPirateCard(selectedHazard) ? '' : selectedHazard,
						].filter((c) => c).length,
						remaining_aging_cards: agingDeck.length,
						robinson_cards: robinsonCardsArr.join(','),
						total_score: score,
					},
					{
						headers: {
							'x-friday-token': getToken(),
						},
					}
				)
				.then((resp) => {
					console.log(resp);
				})
				.catch((err) => {
					setShowNetworkIssueStatus(true);
				});
		}
	}, [
		showGameOverModal,
		showStartupModal,
		initialRequestFailed,
		totalGameTime,
	]);

	useLayoutEffect(() => {
		if (showNetworkIssueStatus) {
			notification.error({
				placement: 'bottomRight',
				duration: 30,
				message: 'Network issue',
				description:
					"Looks like there is a network issue trying to connect to the server at this time. You can still play the game in offline mode. However, your game scores can't be recorded for highscores.",
			});
		}
	}, [showNetworkIssueStatus]);

	useLayoutEffect(() => {
		let URL = process.env.REACT_APP_BACKEND_URL + '/status';
		let performHeartbeat = false;
		// not performing status calls when startup modal or game over modal is open and if initial request to the server failed
		if (!showStartupModal && !showGameOverModal && !initialRequestFailed) {
			performHeartbeat = true;
		}

		if (backendHeartbeatWorker) {
			backendHeartbeatWorker.postMessage({
				URL,
				performHeartbeat,
			});
		}
	}, [
		showStartupModal,
		showGameOverModal,
		initialRequestFailed,
		backendHeartbeatWorker,
	]);

	useLayoutEffect(() => {
		const inTheList = listOfAvailableSpeicalAbilities.map((ls) => ls.card);

		let newCardsToBeAddedToTheList = [];
		let cardsToRemoveFromTheList = [];

		[...selectedRobinsonCards, ...additionalCardsDrawn].forEach((c) => {
			if (c && !inTheList.includes(c) && cards[c].specialAbility) {
				newCardsToBeAddedToTheList.push(c);
			}
		});

		inTheList.forEach((c) => {
			if (![...selectedRobinsonCards, ...additionalCardsDrawn].includes(c)) {
				cardsToRemoveFromTheList.push(c);
			}
		});

		addCardToHavingSpecialAbility(
			newCardsToBeAddedToTheList,
			cardsToRemoveFromTheList
		);
	}, [selectedRobinsonCards, additionalCardsDrawn]);

	// end the game, once the user runs out of aging cards
	useLayoutEffect(() => {
		if (agingDeck.length === 0 && robinsonDeck.length === 0) {
			setOutOfAgingCards(true);
		}
	}, [agingDeck, robinsonDeck]);

	useLayoutEffect(() => {
		if (showGameOverModal) {
			let timeObj = {};
			if (timedMode > 0) {
				timeObj = computeRemainingTime(
					timedModeMap[timedMode] * 60 - remainingTime
				);
			} else {
				timeObj = computeRemainingTime(
					(new Date().getTime() - gameStartTime.getTime()) / 1000
				);
			}

			setTotalGameTime(timeObj);
		}
	}, [showGameOverModal]);

	const computeRemainingTime = (tmp) => {
		let objToReturn = {};

		objToReturn.min = numPadding(parseInt(tmp / 60));
		objToReturn.sec = numPadding((tmp - objToReturn.min * 60).toFixed(0));
		objToReturn.totalSecs = tmp;

		objToReturn.str = `${objToReturn.min} min ${objToReturn.sec} sec`;

		objToReturn.minStr = `${objToReturn.min}:${objToReturn.sec}`;

		return objToReturn;
	};

	const calculateGameScore = () => {
		let tmpScore = 0;

		if (gameMode === 1) {
			return tmpScore;
		}

		const tmpList = [...selectedRobinsonCards, ...additionalCardsDrawn].filter(
			(c) => !cardsAlreadyDestroyed.includes(c)
		);

		[...robinsonDeck, ...robinsonDiscardPile, ...tmpList]
			.filter((c) => c)
			.forEach((c) => {
				if (isAgingCard(c)) {
					tmpScore -= 5;
				} else {
					tmpScore += cards[c].fightValue;
				}
			});

		const wonPiratesScore = wonPirates.length * 15;
		tmpScore += wonPiratesScore;

		if (lifeInHand > 0) {
			const lifeInHandScore = lifeInHand * 5;
			tmpScore += lifeInHandScore;
		}

		tmpScore -=
			[
				...hazardDiscardPile,
				...hazardDeck,
				isPirateCard(selectedHazard) ? '' : selectedHazard,
			].filter((c) => c).length * 3;

		setScore(tmpScore);
	};

	const allHazardCardsAreDealt = () => {
		return (
			hazardDeck.length === 0 &&
			hazardDiscardPile.length === 0 &&
			(!selectedHazard || (selectedHazard && isPirateCard(selectedHazard)))
		);
	};

	const getFightScoreForTheMode = (cardID) => {
		if (!cardID) {
			return 0;
		}

		if (isPirateCard(selectedHazard)) {
			if ('remainingAgingCardsAddsUp' in cards[cardID]) {
				if (remainingAgingCardsAddsUpValRef.current === 0) {
					const pointsArr = [
						...cardsAlreadyDestroyed,
						...eliminatedCards,
						...robinsonDiscardPile,
						...additionalCardsDrawn,
						...selectedRobinsonCards,
						...robinsonDeck,
					].map((c) => (isAgingCard(c) ? 2 : 0));

					if (pointsArr.length === 0) {
						// this is to prevent gaming the system
						return 100;
					}

					remainingAgingCardsAddsUpValRef.current = pointsArr.reduce(
						(a, b) => a + b
					);
				}

				return remainingAgingCardsAddsUpValRef.current;
			}

			if ('fightAgainstAllRemainingHazardCards' in cards[cardID]) {
				let requiredFightScoreToWin = 0;

				[...hazardDeck, ...hazardDiscardPile].forEach((a) => {
					requiredFightScoreToWin += cards[a].levels.hard;
				});

				return requiredFightScoreToWin;
			}

			return cards[cardID].requiredFightScoreToWin;
		}

		if (gameMode === 1) {
			return validateObject(cards, [cardID, 'levels', 'easy'], 0);
		} else if (gameMode === 2) {
			return validateObject(cards, [cardID, 'levels', 'medium'], 0);
		} else {
			return validateObject(cards, [cardID, 'levels', 'hard'], 0);
		}
	};

	const addLife = (num) => {
		if (lifeInReserve === 0) {
			return;
		}

		if (lifeInReserve >= num) {
			setLifeInHand(lifeInHand + num);
			setLifeInReserve(lifeInReserve - num);
		} else if (lifeInReserve < num) {
			setLifeInHand(lifeInHand + num - lifeInReserve);
			setLifeInReserve(0);
		}
	};

	const subtractLife = (num) => {
		if (lifeInHand - num < 0) {
			// show game over UI
			setGameOver(true);
			_setShowGameOverModal(true);
		}

		setLifeInReserve(lifeInReserve + num);
		setLifeInHand(lifeInHand - num);
	};

	const shuffleRobinsonCardsWithAnAgingCard = (rD = [], aD = [], rDP = []) => {
		// end the game, if the user ran out of aging cards
		if (aD.length === 0 && rD.length === 0) {
			_setShowGameOverModal(true);
			return;
		}

		if (rD.length > 0) {
			notification['warning']({
				message: 'Shuffling Robinson deck',
				description:
					'You cannot shuffle robinson deck while there are still cards in the deck.',
			});

			return;
		}

		let topAgingCard = '';
		let shuffledAgingCards = [];

		if (aD.length > 0) {
			topAgingCard = aD.pop();
		}

		_setRobinsonDeck(shuffle([...rDP, topAgingCard].filter((c) => c)));
		_setRobinsonDiscardPile([]);

		if (shuffledAgingCards.length > 0) {
			_setAgingDeck(shuffledAgingCards);
		} else {
			_setAgingDeck(aD);
		}
	};

	const shuffleHazardCards = (hDP = [], gM = defaultGameMode) => {
		if (hazardDeckRef.current.length > 0) {
			notification['warning']({
				message: 'Shuffling Hazard deck',
				description:
					'You cannot shuffle hazard deck while there are still cards in the deck.',
			});

			return;
		}

		_setHazardDeck(shuffle(hDP));
		_setHazardDiscardPile([]);

		if (gM < 3) {
			_setGameMode(gM + 1);
		}
	};

	const hasWonTheFight = () => {
		return currentFightScore >= getFightScoreForTheMode(selectedHazard);
	};

	const isAgingCard = (card) => {
		if (card) {
			return 'superAged' in cards[card];
		}

		return false;
	};

	const isPirateCard = (card) => {
		if (card) {
			return card.startsWith('p-');
		}

		return false;
	};

	const getCardsToResolve = (forResolveModal = false) => {
		let cardsToResolve = [...selectedRobinsonCards, ...additionalCardsDrawn];

		if (
			selectedSpecialAbilityCard &&
			'copy' in cards[selectedSpecialAbilityCard] &&
			!forResolveModal
		) {
			cardsToResolve = cardsToResolve.filter((c) => c && !isAgingCard(c));
		}

		if (
			selectedSpecialAbilityCard &&
			'double' in cards[selectedSpecialAbilityCard] &&
			!forResolveModal
		) {
			cardsToResolve = cardsToResolve.filter(
				(c) =>
					c &&
					!isAgingCard(c) &&
					'fightValue' in cards[c] &&
					cards[c].fightValue > 0
			);
		}

		// ?????? removing the active copy card from the cards to be resolved
		// if (consecutiveStepInCopyWithCopyCard) {
		// 	cardsToResolve = cardsToResolve.filter(
		// 		(c) => consecutiveStepInCopyWithCopyCard !== c
		// 	);
		// }
		// ?????? need to come to a conclusion on this

		// helps to show only required cards to resolve while using copy special ability
		if (
			stopDrawingFightCards &&
			!forResolveModal &&
			'copy' in cards[selectedSpecialAbilityCard]
		) {
			cardsToResolve = cardsToResolve.filter(
				(c) => !isCardSupposedToBeExcludedOnDoubleAndPhase1(c)
			);
		}

		const cardsToReturn = cardsToResolve.filter((card) => card);

		return cardsToReturn;
	};

	const resetAgingPowers = (cardSelected) => {
		const actualCard = cards[cardSelected];

		if (isAgingCard(cardSelected)) {
			if ('highestCardIsZero' in actualCard) {
				getHighestCardThatShouldBeZero([
					...selectedRobinsonCards,
					...additionalCardsDrawn,
				]);
				setHighestCardIsZero(false);
			}

			if ('stop' in actualCard) {
				setStopped(false);
			}

			return true;
		}

		return false;
	};

	const recursivelyAddUntilTheConditionIsMet = (deck, arrToAdd, limit) => {
		while (deck.length > 0 && arrToAdd.length < limit) {
			arrToAdd.push(deck.pop());

			// fail safe check
			if (arrToAdd.length === limit) {
				return arrToAdd;
			}
		}

		return arrToAdd;
	};

	const hazardSelectionOnOkay = (hC = '', hDP = [], sHC = []) => {
		if (!hC) {
			notification['warning']({
				message: 'Hazard card selection',
				description: 'Please select a hazard card before saying continue.',
			});

			return;
		}

		_setHazardSelectionModal(false);

		_setHazardDiscardPile([...hDP, ...sHC.filter((c) => c !== hC)]);

		_setSelectedHazardCards([]);

		_setIsFightOn(true);
	};

	const shuffleAndAddCardsToRobinsonDeckAndDrawTheCardsToAdditionalCardsPile = (
		card
	) => {
		const numberOfCardsToAdd = cards[card].addCard;
		let cardsToInsert = [];
		let shuffledAgingCards = [];
		let topAgingCard = '';

		cardsToInsert = recursivelyAddUntilTheConditionIsMet(
			robinsonDeck,
			cardsToInsert,
			numberOfCardsToAdd
		);

		if (cardsToInsert.length >= numberOfCardsToAdd) {
			return;
		}

		if (agingDeck.length > 0) {
			topAgingCard = agingDeck.pop();

			if (agingDeck.length === 0) {
				shuffledAgingCards = shuffle([
					...agingCards,
					...superAgingCards,
					difficultAgingCard,
				]);
			}
		}

		let tmpRobinsonDeck = shuffle(
			[...robinsonDiscardPile, topAgingCard].filter((c) => c)
		);

		cardsToInsert = recursivelyAddUntilTheConditionIsMet(
			tmpRobinsonDeck,
			cardsToInsert,
			numberOfCardsToAdd
		);

		const tmpAdditionalCardsDrawn = [...additionalCardsDrawn, ...cardsToInsert];

		setAlreadySelectedCards([...alreadySelectedCards, card]);

		const tmpNewScore = getFightScore(
			[...selectedRobinsonCards, ...tmpAdditionalCardsDrawn],
			[...cardsAlreadyDoubled],
			[
				...cardsAlreadyDestroyed,
				getHighestCardThatShouldBeZero([
					...selectedRobinsonCards,
					...tmpAdditionalCardsDrawn,
					...cardsToInsert,
				]),
			]
		);

		setCurrentFightScore(tmpNewScore);

		// last things to do
		_setRobinsonDiscardPile([]);
		if (shuffledAgingCards.length > 0) {
			_setAgingDeck(shuffledAgingCards);
		} else {
			_setAgingDeck(agingDeck);
		}
		setAdditionalCardsDrawn(tmpAdditionalCardsDrawn);
		_setRobinsonDeck(tmpRobinsonDeck);
	};

	const shuffleAndAddCardsToRobinsonDeckAndMakeSort3Happen = (card, elem) => {
		const limitToObey = 3;
		let newCards = [];
		let topAgingCard = '';
		let shuffledAgingCards = [];

		newCards = recursivelyAddUntilTheConditionIsMet(
			robinsonDeck,
			newCards,
			limitToObey
		);

		if (newCards.length < limitToObey) {
			if (agingDeck.length > 0) {
				topAgingCard = agingDeck.pop();

				if (agingDeck.length === 0) {
					shuffledAgingCards = shuffle([
						...agingCards,
						...superAgingCards,
						difficultAgingCard,
					]);
				}
			}

			let tmpRobinsonDeck = shuffle(
				[...robinsonDiscardPile, topAgingCard].filter((c) => c)
			);

			newCards = recursivelyAddUntilTheConditionIsMet(
				tmpRobinsonDeck,
				newCards,
				limitToObey
			);

			// things to do at the end
			_setRobinsonDiscardPile([]);
			if (shuffledAgingCards.length > 0) {
				_setAgingDeck(shuffledAgingCards);
			} else {
				_setAgingDeck(agingDeck);
			}
			_setRobinsonDeck(tmpRobinsonDeck);
		} else {
			_setRobinsonDeck(robinsonDeck);
		}

		setTop3CardsFromRobinsonDeck(newCards);

		setTimeout(() => {
			setShowTheResolveSort3Modal(true);
			setAlreadySelectedCards([...alreadySelectedCards, card]);
		}, 200);
	};

	const shuffleAndExchangeRobinsonCards = (card, elem) => {
		let topAgingCard = '';
		let shuffledAgingCards = [];

		if (agingDeck.length > 0) {
			topAgingCard = agingDeck.pop();

			if (agingDeck.length === 0) {
				shuffledAgingCards = shuffle([
					...agingCards,
					...superAgingCards,
					difficultAgingCard,
				]);
			}
		}

		let tmpRobinsonDeck = shuffle(
			[...robinsonDiscardPile, topAgingCard].filter((c) => c)
		);

		// last things to do
		_setRobinsonDiscardPile([]);

		if (shuffledAgingCards.length > 0) {
			_setAgingDeck(shuffledAgingCards);
		} else {
			_setAgingDeck(agingDeck);
		}

		_setRobinsonDeck(tmpRobinsonDeck);

		setTimeout(() => {
			setSkipTheCardToExchange(card);
			setNumberOfCardsToExchange(cards[card].exchange);

			setShowTheResolveExchange(true);
			setAlreadySelectedCards([...alreadySelectedCards, card]);
		}, 200);
	};

	const handleClickOnSelectedRobinsonCards = (e, card) => {
		if (cards[card].specialAbility && !isAgingCard(card)) {
			setSelectedSpecialAbilityCard(card);
			const elem = e.currentTarget;
			// when copy card is active it bypasses the selected rule
			if (!elem.className.includes('selected') || isCopyCardActive) {
				if ('addLife' in cards[card] && cards[card].addLife > 0) {
					addLife(cards[card].addLife);
					setAlreadySelectedCards([...alreadySelectedCards, card]);

					updateCardWithSpecialAbility(card);

					setConsecutiveStepInCopyWithCopyCard('');
				}

				if ('addCard' in cards[card] && cards[card].addCard > 0) {
					let cardsToInsert = [];

					if (
						robinsonDeck.length === 0 ||
						(cards[card].addCard === 2 && robinsonDeck.length === 1)
					) {
						Modal.confirm({
							title: 'Confirm',
							icon: <ExclamationCircleOutlined />,
							content:
								'Not enough cards to perform the action! Do you want to shuffle the cards and give a try?',
							okText: 'Yes',
							cancelText: 'No',
							onOk: () => {
								updateCardWithSpecialAbility(card);

								shuffleAndAddCardsToRobinsonDeckAndDrawTheCardsToAdditionalCardsPile(
									card
								);

								setConsecutiveStepInCopyWithCopyCard('');
								setIsCopyCardActive(false);
							},
							onCancel: () => {
								resolveConsecutiveStepInCopyWithCopyCard();
							},
						});
						return;
					}

					if (robinsonDeck.length > 0) {
						cardsToInsert.push(robinsonDeck.pop());
					}

					if (cards[card].addCard === 2 && robinsonDeck.length > 0) {
						cardsToInsert.push(robinsonDeck.pop());
					}

					setAdditionalCardsDrawn([...additionalCardsDrawn, ...cardsToInsert]);
					_setRobinsonDeck(robinsonDeck.filter((c) => c));

					setAlreadySelectedCards([...alreadySelectedCards, card]);

					setCurrentFightScore(
						getFightScore(
							[
								...selectedRobinsonCards,
								...additionalCardsDrawn,
								...cardsToInsert,
							],
							[...cardsAlreadyDoubled],
							[
								...cardsAlreadyDestroyed,
								getHighestCardThatShouldBeZero([
									...selectedRobinsonCards,
									...additionalCardsDrawn,
									...cardsToInsert,
								]),
							]
						)
					);

					updateCardWithSpecialAbility(card);

					setConsecutiveStepInCopyWithCopyCard('');
				}

				if ('double' in cards[card]) {
					if (
						[...selectedRobinsonCards, ...additionalCardsDrawn].length === 1
					) {
						notification['warning']({
							message: 'Double',
							description: 'Not enough cards to use this power!',
						});

						return;
					}

					const applyDouble = (c) => {
						setAlreadySelectedCards([...alreadySelectedCards, c]);
						setTempBarFromDoublingTheOwnCard(c);
						setShowTheResolveDoubleModal(true);
					};

					if (stopDrawingFightCards) {
						applyDouble(card);

						return;
					}

					Modal.confirm({
						title: 'Confirm',
						icon: <ExclamationCircleOutlined />,
						content:
							'Once you use this power successfully, you wont be able to draw anymore robinson cards. Do you want to proceed?',
						okText: 'Yes',
						cancelText: 'No',
						onOk: () => {
							applyDouble(card);
						},
					});
				}

				if ('1xbelow' in cards[card]) {
					if (robinsonDeck.length === 0) {
						notification['warning']({
							message: '1x below the pile',
							description:
								'Please shuffle the robinson deck and try using this special ability.',
						});

						return;
					}

					if (getCardsToResolve().length <= 1) {
						notification['warning']({
							message: '1x below the pile',
							description: 'Not enough cards to perform this action.',
						});

						return;
					}

					setCardsToSkip1xbelowResolve([
						...cardsToSkip1xbelowResolve,
						...cardsAlreadyDestroyed,
						card,
					]);

					setShowTheResolve1xbelowModal(true);
					setAlreadySelectedCards([...alreadySelectedCards, card]);
				}

				if ('phase-1' in cards[card]) {
					if (gameMode <= 1 || fightingPirates || gameMode > 3) {
						notification['warning']({
							message: 'Phase -1',
							description:
								'You cannot use this power in easy mode or while fighting pirate cards.',
						});

						resolveConsecutiveStepInCopyWithCopyCard();

						return;
					}
					const applyPhase1 = () => {
						_setGameMode(gameMode - 1);
						setReducedGameMode(reducedGameMode + 1);
						setAlreadySelectedCards([...alreadySelectedCards, card]);

						if (consecutiveStepInCopyWithCopyCard) {
							setConsecutiveStepInCopyWithCopyCard('');
							setIsCopyCardActive(false);
						}

						updateCardWithSpecialAbility(card);
					};

					// goes through if the player has already selected a double card which warrants `stopDrawingFightCards`
					if (stopDrawingFightCards) {
						applyPhase1();
						return;
					}

					Modal.confirm({
						title: 'Confirm',
						icon: <ExclamationCircleOutlined />,
						content:
							'Once you start using this power you wont be able to draw anymore robinson cards. Do you want to proceed?',
						okText: 'Yes',
						cancelText: 'No',
						onOk: () => {
							setStopDrawingFightCards(true);
							applyPhase1();
						},
					});

					setConsecutiveStepInCopyWithCopyCard('');
				}

				if ('destroy' in cards[card]) {
					if ([...selectedRobinsonCards, ...additionalCardsDrawn].length <= 1) {
						notification['warning']({
							message: 'Destroy',
							description:
								'Not enough fight cards selected to perform the action.',
						});

						return;
					}

					setCardsToSkipDestroyResolve([
						...cardsToSkipDestroyResolve,
						...cardsAlreadyDestroyed,
						card,
					]);

					setShowTheResolveDestroyModal(true);
					setAlreadySelectedCards([...alreadySelectedCards, card]);
				}

				if ('sort3Cards' in cards[card]) {
					if (robinsonDeck.length < 3) {
						Modal.confirm({
							title: 'Confirm',
							icon: <ExclamationCircleOutlined />,
							content:
								'Not enough cards to perform the action! Do you want to shuffle the cards and give a try?',
							okText: 'Yes',
							cancelText: 'No',
							onOk: () => {
								setConsecutiveStepInCopyWithCopyCard('');
								setIsCopyCardActive(false);

								return shuffleAndAddCardsToRobinsonDeckAndMakeSort3Happen(
									card,
									elem
								);
							},
							onCancel: () => {
								resolveConsecutiveStepInCopyWithCopyCard();
							},
						});

						return;
					}

					let newCards = [];
					newCards.unshift(robinsonDeck.pop());
					newCards.unshift(robinsonDeck.pop());
					newCards.unshift(robinsonDeck.pop());

					setTop3CardsFromRobinsonDeck(newCards);
					_setRobinsonDeck(robinsonDeck.filter((c) => c));

					setTimeout(() => {
						setShowTheResolveSort3Modal(true);
						setAlreadySelectedCards([...alreadySelectedCards, card]);
					}, 200);
				}

				if ('exchange' in cards[card]) {
					if (getCardsToResolve().length === 1) {
						notification['warning']({
							message: 'Exchange',
							description:
								'Not enough cards in robinson deck or the fight cards to perform this action.',
						});
						return;
					}

					if (
						robinsonDeck.length === 0 ||
						robinsonDeck.length < cards[card].exchange
					) {
						Modal.confirm({
							title: 'Confirm',
							icon: <ExclamationCircleOutlined />,
							content:
								'Not enough cards to perform the action! Do you want to shuffle the cards and give a try?',
							okText: 'Yes',
							cancelText: 'No',
							onOk: () => {
								setConsecutiveStepInCopyWithCopyCard('');
								setIsCopyCardActive(false);

								return shuffleAndExchangeRobinsonCards(card, elem);
							},
							onCancel: () => {
								resolveConsecutiveStepInCopyWithCopyCard();
							},
						});
						return;
					}

					setSkipTheCardToExchange(card);
					setNumberOfCardsToExchange(cards[card].exchange);

					setShowTheResolveExchange(true);
					setAlreadySelectedCards([...alreadySelectedCards, card]);
				}

				if ('copy' in cards[card]) {
					let counter = 0;
					let cardsToSkip = [];

					let tmpMap = {};

					listOfAvailableSpeicalAbilities.forEach((cInfo) => {
						if (cInfo.alreadyUsed) {
							tmpMap[cInfo.card] = {
								specialAbility: cInfo.specialAbility,
								alreadyUsed: cInfo.alreadyUsed,
								fightValue: cInfo.fightValue,
							};
						}
					});

					[...selectedRobinsonCards, ...additionalCardsDrawn].forEach((c) => {
						if (
							c &&
							card !== c &&
							cards[c].specialAbility &&
							tmpMap[c] &&
							tmpMap[c].alreadyUsed &&
							!isAgingCard(c)
						) {
							counter++;
						} else if (c) {
							cardsToSkip.push(c);
						}
					});

					if (counter === 0) {
						notification['warning']({
							message: 'Copy',
							description:
								"Used special abilities weren't found in any of the selected fight cards to perform this action.",
						});

						return;
					}

					cardsToSkip = [
						...new Set([...skipTheCardsToCopy, card, ...cardsToSkip]),
					];

					// filter cardsToSkip to remove cards that are in tmpMap
					cardsToSkip = cardsToSkip.filter((c) => !tmpMap[c]);

					setSkipTheCardsToCopy(cardsToSkip);

					setShowTheResolveCopy(true);
					setAlreadySelectedCards([...alreadySelectedCards, card]);

					setConsecutiveStepInCopyWithCopyCard(card);
				}
			}

			setIsCopyCardActive(false);
		}
	};

	const applySpecialAbilitiesOfAgingCards = (card) => {
		if (isAgingCard(card)) {
			if ('stop' in cards[card]) {
				setStopped(true);

				if (freeCardsLimit - selectedRobinsonCards.length > 0) {
					Modal.confirm({
						icon: <ExclamationCircleOutlined />,
						content: (
							<p>
								Stop: You won't be able to pick anymore free cards if you don't
								destroy the card now!
							</p>
						),
						okText: 'Destroy!',
						onOk() {
							subtractLife(2); // paying 2 lifePoints for removing this card
							setStopped(false);
							setCardsAlreadyDestroyed([...cardsAlreadyDestroyed, card]);
						},
					});
				} else {
					setStopped(false); // setting the stopped to false when there are no free cards available
				}
			}

			if ('highestCardIsZero' in cards[card]) {
				setHighestCardIsZero(true);

				let tmpArr = getCardsToResolve().map((c) => cards[c].fightValue);

				const highestNum = Math.max(...tmpArr);

				const total = tmpArr.reduce((a, b) => a + b);

				setCurrentFightScore(total - highestNum);
			}
		}
	};

	const getFightScore = (listOfCards, cardsToBeDoubled, cardsToExclude) => {
		// score calculation for this pirate card only
		if (onlyHalfTheCardsCountBeingFought) {
			let cardsThatMakeItToTheList = [];
			let highestCardToBeExcluded =
				getHighestCardThatShouldBeZero(cardsToExclude);

			if (highestCardToBeExcluded === undefined) {
				highestCardToBeExcluded = '';
			} else if (highestCardToBeExcluded) {
				listOfCards = [...listOfCards, highestCardToBeExcluded];
			}

			let cardsThatCanBeCounted = listOfCards.filter(
				(c) => !(cardsToExclude.includes(c) || cardsToBeDoubled.includes(c))
			);

			const agingCards = cardsThatCanBeCounted.filter((c) => isAgingCard(c));

			cardsThatCanBeCounted = cardsThatCanBeCounted.filter(
				(c) => !isAgingCard(c)
			);

			cardsThatMakeItToTheList = [
				...agingCards,
				...cardsToBeDoubled,
				...cardsThatCanBeCounted
					.sort((a, b) => cards[a].fightValue - cards[b].fightValue)
					.reverse(),
			];

			const numberOfCardsThatCanBeCounted = Math.ceil(
				cardsThatMakeItToTheList.length / 2
			);

			let finalScore = 0;

			for (let i = 0; i < numberOfCardsThatCanBeCounted; i++) {
				const card = cardsThatMakeItToTheList[i];

				if (highestCardToBeExcluded && card === highestCardToBeExcluded) {
					continue; // card fight values should not be counted
				} else if (cardsToBeDoubled.includes(card)) {
					finalScore += cards[card].fightValue * 2;
				} else {
					finalScore += cards[card].fightValue;
				}
			}

			return finalScore;
		}

		// regular score calculation
		const fScores = listOfCards.map((card) => {
			if (card && !cardsToExclude.includes(card)) {
				const tmp = cards[card];

				if (cardsToBeDoubled.includes(card)) {
					return tmp.fightValue * 2;
				}

				return tmp.fightValue;
			}

			return 0;
		});

		if (
			isPirateCard(selectedHazard) &&
			'eachFightingCardIsAFightingPoint' in cards[selectedHazard]
		) {
			fScores.push(listOfCards.length);
		}

		return fScores.reduce((a, b) => a + b);
	};

	const getHighestCardThatShouldBeZero = (listOfCards) => {
		if (!listOfCards || listOfCards.length === 0) {
			return;
		}

		let highestNum = 0;
		let cardToSkip = '';

		if (highestCardIsZero) {
			listOfCards.forEach((c) => {
				if (c && !cardsAlreadyDestroyed.includes(c)) {
					const fightValue = cards[c].fightValue;
					if (fightValue > highestNum) {
						highestNum = fightValue;
						cardToSkip = c;
					}
				}
			});
		}

		setHighestCardIsZeroCard(cardToSkip);

		return cardToSkip;
	};

	const renderSelectedCard = (card) => {
		if (!card) {
			return;
		}

		// may end up being `true` effectively when double or phase -1 special abilites are used
		let notAvailable = false;
		let tags = [];

		if (cardsAlreadyDoubled.includes(card)) {
			tags.push('Doubled');
		}

		if (highestCardIsZeroCard === card) {
			tags.push('Fight value = 0 | due to highest card is zero');
		}

		if (isCardSupposedToBeExcludedOnDoubleAndPhase1(card)) {
			notAvailable = true;
			tags.push(
				'Special ability is not available (Double or Phase-1 has been played)'
			);
		}

		return card.startsWith('h-') ? (
			<Card
				id={card}
				className={`${
					(isAgingCard(card) &&
						cards[card].specialAbility &&
						!cardsExplicitlyDestroyed.includes(card) &&
						!cardsAlreadyDestroyed.includes(card)) ||
					(!isAgingCard(card) && alreadySelectedCards.includes(card))
						? 'selected'
						: ''
				}`}
				lifePoints={cards[card].lifePoints}
				fightValue={cards[card].fightValue}
				state={cards[card].state}
				specialAbility={cards[card].specialAbility}
				onClickCallback={
					cards[card].specialAbility && !notAvailable
						? (e) => {
								handleClickOnSelectedRobinsonCards(e, card);
						  }
						: null
				}
				style={{
					position: 'relative',
				}}
				onRender={() => {
					applySpecialAbilitiesOfAgingCards(card);
				}}
				diffcultLevel={cards[card].levels.hard}
				mediumLevel={cards[card].levels.medium}
				easyLevel={cards[card].levels.easy}
				hazardDescription={cards[card].hazard}
				freeCards={cards[card].freeCards}
				imageNum={cards[card].imageNum}
				hazard
				front={
					!(
						cardsExplicitlyDestroyed.includes(card) ||
						cardsAlreadyDestroyed.includes(card)
					)
				}
				tags={tags}
			/>
		) : (
			<Card
				id={card}
				className={`${
					(isAgingCard(card) &&
						cards[card].specialAbility &&
						!cardsExplicitlyDestroyed.includes(card) &&
						!cardsAlreadyDestroyed.includes(card)) ||
					(!isAgingCard(card) && alreadySelectedCards.includes(card))
						? 'selected'
						: ''
				}`}
				lifePoints={cards[card].lifePoints}
				fightValue={cards[card].fightValue}
				state={cards[card].state}
				specialAbility={cards[card].specialAbility}
				onClickCallback={
					cards[card].specialAbility && !notAvailable
						? (e) => {
								handleClickOnSelectedRobinsonCards(e, card);
						  }
						: null
				}
				style={{
					position: 'relative',
				}}
				onRender={() => {
					applySpecialAbilitiesOfAgingCards(card);
				}}
				front={
					!(
						cardsExplicitlyDestroyed.includes(card) ||
						cardsAlreadyDestroyed.includes(card)
					)
				}
				tags={tags}
			/>
		);
	};

	const addCardToHavingSpecialAbility = (newCards, cardsToRemove = []) => {
		if (newCards.length <= 0 && cardsToRemove.length <= 0) {
			return;
		}

		newCards = newCards.filter((c) => c);

		let toAddToTheList = newCards.map((c) => {
			return {
				card: c,
				specialAbility: cards[c].specialAbility,
				alreadyUsed: isAgingCard(c) && cards[c].specialAbility ? true : false,
				fightValue: cards[c].fightValue,
			};
		});

		setListOfAvailableSpeicalAbilities([
			...listOfAvailableSpeicalAbilities.filter(
				(ls) => !cardsToRemove.includes(ls.card)
			),
			...toAddToTheList,
		]);
	};

	const updateCardWithSpecialAbility = (card) => {
		setListOfAvailableSpeicalAbilities(
			listOfAvailableSpeicalAbilities.map((c) => {
				if (card === c.card) {
					c.alreadyUsed = true;
				}
				return c;
			})
		);
	};

	const isCardSupposedToBeExcludedOnDoubleAndPhase1 = (card) => {
		if (!stopDrawingFightCards || !card) {
			return false;
		}

		for (let i = 0; i < cardsThatCanBeExcludedOnDoubleAndPhase1.length; i++) {
			let c = cardsThatCanBeExcludedOnDoubleAndPhase1[i];
			if (c in cards[card]) {
				return true;
			}
		}

		return false;
	};

	const renderTrails = (abilities) => {
		return abilities.map((lofsa) => {
			const markUsed =
				lofsa.alreadyUsed ||
				isCardSupposedToBeExcludedOnDoubleAndPhase1(lofsa.card);

			return (
				<div
					className={`specialAbilityToApply t-a:c f-w:bo${
						cardsAlreadyDestroyed.includes(lofsa.card)
							? ' alreadyDestroyed'
							: markUsed
							? ' alreadyUsed'
							: ''
					}${!markUsed ? ' c:p' : ''}`}
					onClick={
						!markUsed
							? () => {
									document.getElementById(lofsa.card).click();
							  }
							: null
					}
					key={lofsa.card}
				>
					{`${lofsa.specialAbility} (${lofsa.fightValue})`}
				</div>
			);
		});
	};

	const removeCardFromAlreadySelectedCards = (card) => {
		if (!card) {
			return;
		}

		setAlreadySelectedCards(alreadySelectedCards.filter((c) => c !== card));
	};

	const resolveConsecutiveStepInCopyWithCopyCard = () => {
		if (consecutiveStepInCopyWithCopyCard) {
			removeCardFromAlreadySelectedCards(consecutiveStepInCopyWithCopyCard);

			const filteredList = listOfAvailableSpeicalAbilities.map((c) => {
				if (consecutiveStepInCopyWithCopyCard === c.card) {
					const nc = c;

					nc.alreadyUsed = false;
				}

				return c;
			});

			setListOfAvailableSpeicalAbilities(filteredList);

			setConsecutiveStepInCopyWithCopyCard('');
		}
	};

	return (
		<div className="gameBoard container">
			<div className="row">
				<NavBar
					className="gameBoard-navBar"
					gameMode={gameMode}
					score={`${currentFightScore}/
					${getFightScoreForTheMode(selectedHazard)} (
					${freeCardsLimit - selectedRobinsonCards.length})`}
					lifePointsInHand={lifeInHand}
					lifePointsInReserve={lifeInReserve}
					totalAgingCards={agingDeck.length}
					hazardDiscardPile={hazardDiscardPile}
					robinsonDiscardPile={robinsonDiscardPile}
					eliminatedDiscardPile={eliminatedCards}
					countdownTimer={remainingTimeToShow}
					isTimerPaused={pauseOption}
					canPause={!isFightOn && !isPirateCard(selectedHazard) && timedMode}
					timedMode={timedMode}
					isFightOn={isFightOn}
					onChange={(sv) => {
						_setPauseOption(sv);
					}}
				/>
			</div>
			<div className="row marginTop-48">
				<div className="col-xs-2 fCenter">
					<div className="gameBoard-info t-a:c">
						<p>
							{/* Hello, <b>{name}</b>! */}
							Hello <b>{name}</b>, you are playing level <b>{gameLevel}</b> in{' '}
							<b>
								{timedModeMap[timedMode] === 0
									? 'Un-timed mode'
									: `${timedModeMap[timedMode]} min time mode`}
							</b>
							, and you're{' '}
							<b className={`${showNetworkIssueStatus ? 'off' : 'on'}`}>
								{showNetworkIssueStatus ? 'Offline' : 'Online'}
							</b>
							!
						</p>

						<Tooltip
							placement="right"
							title={`${
								isBaseGameCompleteRef.current && wonPirates.length === 2
									? 'Finishes the game and records your score!'
									: "Give's up the game, not just the fight!"
							}`}
						>
							<Button
								type="primary"
								onClick={() => {
									if (
										!(isBaseGameCompleteRef.current && wonPirates.length === 2)
									) {
										Modal.confirm({
											title: 'Confirm',
											icon: <ExclamationCircleOutlined />,
											content: 'Are you sure you want to give up?',
											okText: 'Yes',
											cancelText: 'No',
											onOk: () => {
												calculateGameScore();
												_setShowGameOverModal(true);
											},
										});
									} else {
										calculateGameScore();
										_setShowGameOverModal(true);
									}
								}}
								// disabled={gameMode === 1}
							>
								{isBaseGameCompleteRef.current && wonPirates.length === 2
									? 'Finish!'
									: 'Give Up!'}
							</Button>
						</Tooltip>
					</div>
				</div>
				{randomPirateCards.map((card) => (
					<div className="col-xs-5 fCenter" key={card}>
						<PirateCard
							className={`gameBoard-pirateCard${
								selectedHazard === card ? ' fightingNow' : ''
							}${lostPirates.includes(card) ? ' lost' : ''}${
								wonPirates.includes(card) ? ' won' : ''
							}`}
							fightValue={cards[card].requiredFightScoreToWin}
							freeCards={cards[card].numberOfFreeCards}
							description={cards[card].description}
							id={card}
							onClickCallback={
								fightingPirates ||
								lostPirates.includes(card) ||
								wonPirates.includes(card) ||
								selectedHazard.startsWith('h-') ||
								!isBaseGameCompleteRef.current
									? null
									: (e) => {
											if (
												'remainingAgingCardsAddsUp' in cards[card] &&
												!isBaseGameCompleteRef.current
											) {
												notification['warning']({
													message: 'Pirates',
													description:
														"You can't play this pirate until you have completed the base game (all three modes - easy, medium, hard)!",
												});

												return;
											}

											if (fightingPirates && selectedHazard !== card) {
												notification['warning']({
													message: 'Pirates',
													description:
														'Please resolve the current fight before starting a new fight with another pirate card!',
												});

												return;
											}
											const id = e.currentTarget.id;

											Modal.confirm({
												title: 'Confirm',
												icon: <ExclamationCircleOutlined />,
												content: 'Are you sure you want to play a pirate now?',
												okText: 'Yes',
												cancelText: 'No',
												onOk: () => {
													// if all hazard cards are played, then we mark the fight against all remaining hazzards as won
													_setIsFightOn(true);

													if (
														'fightAgainstAllRemainingHazardCards' in
															cards[id] &&
														hazardDeck.length === 0 &&
														hazardDiscardPile.length === 0 &&
														!selectedHazard.startsWith('h-') &&
														isBaseGameCompleteRef.current
													) {
														setWonPirates([...wonPirates, id]);
														return;
													}

													_setSelectedHazard(id);
													if ('onlyHalfTheCardsCount' in cards[id]) {
														setOnlyHalfTheCardsCountBeingFought(true);
													}
													setFightingPirates(true);
												},
											});
									  }
							}
						/>
					</div>
				))}
			</div>
			<div className="row marginTop-24">
				<div className="col-xs-3">
					<div className="deck mCenter">
						<div className="t-a:c deckHeading">
							Hazard deck <small>({hazardDeck.length})</small>
						</div>
						{hazardDeck.length > 0 ? (
							<Card
								back
								key_id="hazardDeck"
								className="mCenter"
								onClickCallback={
									fightingPirates || isFightOn || gameOver
										? null
										: () => {
												if (selectedHazardCards.length > 0 || isFightOn) {
													notification['warning']({
														message: 'Hazard deck',
														description:
															'You cannot select more hazard cards until you resolve the previous selection.',
													});

													return;
												}

												if (selectedHazardCards.length === 0 && !isFightOn) {
													const selectedCard1 = hazardDeck.pop();
													const selectedCard2 = hazardDeck.pop();
													_setHazardDeck(hazardDeck);

													let selectedCards = [];

													if (selectedCard1) {
														selectedCards.push(selectedCard1);
													}

													if (selectedCard2) {
														selectedCards.push(selectedCard2);
													}

													_setSelectedHazardCards(selectedCards);
													_setHazardSelectionModal(true);
												}
										  }
								}
							/>
						) : (
							<div className="t-a:c">
								<p>Please shuffle the hazard discard pile to continue</p>
								<Button
									onClick={() =>
										shuffleHazardCards(hazardDiscardPile, gameMode)
									}
									disabled={isFightOn}
								>
									Shuffle
								</Button>
							</div>
						)}
					</div>
				</div>

				<div className="col-xs-6">
					<div className="row">
						<div className="col-xs-12 p:r">
							{selectedHazard && (
								<>
									<div className="deckHeading t-a:c">Current fight:</div>
									<div
										style={{
											display: showSpecialAbilitiesTrail ? 'block' : 'none',
										}}
										className="specialAbilitiesList"
									>
										<p>Trail</p>
										{renderTrails(listOfAvailableSpeicalAbilities)}
									</div>
									{isPirateCard(selectedHazard) ? (
										<PirateCard
											fightValue={cards[selectedHazard].requiredFightScoreToWin}
											freeCards={cards[selectedHazard].numberOfFreeCards}
											description={cards[selectedHazard].description}
											id={selectedHazard}
											className="m:a"
										/>
									) : (
										<Card
											id={selectedHazard}
											className="mCenter"
											lifePoints={cards[selectedHazard].lifePoints}
											fightValue={cards[selectedHazard].fightValue}
											state={cards[selectedHazard].state}
											specialAbility={cards[selectedHazard].specialAbility}
											hazard
											showHazard
											diffcultLevel={cards[selectedHazard].levels.hard}
											mediumLevel={cards[selectedHazard].levels.medium}
											easyLevel={cards[selectedHazard].levels.easy}
											hazardDescription={cards[selectedHazard].hazard}
											freeCards={cards[selectedHazard].freeCards}
											imageNum={cards[selectedHazard].imageNum}
											front
										/>
									)}

									<Popconfirm
										className="KPS-resolveFight"
										title={`Are you sure${
											!hasWonTheFight() ? "(you haven't won the fight yet)" : ''
										}?`}
										onConfirm={() => {
											if (
												[...selectedRobinsonCards, ...additionalCardsDrawn]
													.length === 0
											) {
												notification['warning']({
													message: 'Resolve fight',
													description:
														'Sorry you cannot resolve fight without fighting the selected hazard!',
												});

												return;
											}
											// show resolve modal
											_setShowResolveModal(true);
										}}
										okButtonProps={{ autoFocus: true }}
									>
										<Button type="ghost" className="m:a d:f marginTop-12">
											Resolve fight
										</Button>
									</Popconfirm>
								</>
							)}
						</div>
					</div>
				</div>

				<div className="col-xs-3">
					<div className="deck mCenter">
						<div className="t-a:c deckHeading">
							Robinson deck <small>({robinsonDeck.length})</small>
						</div>

						{robinsonDeck.length > 0 ? (
							<Card
								back
								className="mCenter"
								key_id="fightDeck"
								onClickCallback={() => {
									if (gameOver) {
										return;
									}

									if (stopDrawingFightCards) {
										notification['warning']({
											message: 'Robinson deck',
											description:
												'Since you have used `double` or `phase -1` cards, you cannot draw any more robinson (fight) cards.',
										});

										return;
									}

									if (
										selectedHazard &&
										freeCardsLimit > selectedRobinsonCards.length &&
										!stopped
									) {
										const selectedCard = robinsonDeck.pop();
										_setRobinsonDeck(robinsonDeck.filter((c) => c));

										setCurrentFightScore(
											getFightScore(
												[
													...selectedRobinsonCards,
													...additionalCardsDrawn,
													selectedCard,
												],
												[...cardsAlreadyDoubled],
												[
													...cardsAlreadyDestroyed,
													getHighestCardThatShouldBeZero([
														...selectedRobinsonCards,
														...additionalCardsDrawn,
														selectedCard,
													]),
												]
											)
										);

										setSelectedRobinsonCards([
											...selectedRobinsonCards,
											selectedCard,
										]);
									} else if (
										selectedHazard &&
										(freeCardsLimit === selectedRobinsonCards.length || stopped)
									) {
										if (lifeInHand <= 0) {
											notification['warning']({
												message: 'Robinson deck',
												description:
													"You don't have enough life points to pay for a card.",
											});

											if (lifeInHand < 0) {
												setGameOver(true);
											}

											return;
										}

										Modal.confirm({
											title: 'Confirm',
											icon: <ExclamationCircleOutlined />,
											content: `Are you sure you want to pay ${
												isPirateCard(selectedHazard) &&
												'doubleTheFightingCardCosts' in cards[selectedHazard]
													? '2'
													: 'a'
											} life point(s) to continue fighting?`,
											okText: 'Yes',
											cancelText: 'No',
											onOk: () => {
												if (
													isPirateCard(selectedHazard) &&
													'doubleTheFightingCardCosts' in cards[selectedHazard]
												) {
													subtractLife(2);
												} else {
													subtractLife(1);
												}

												const selectedCard = robinsonDeck.pop();
												_setRobinsonDeck(robinsonDeck.filter((c) => c));

												setCurrentFightScore(
													getFightScore(
														[
															...selectedRobinsonCards,
															...additionalCardsDrawn,
															selectedCard,
														],
														[...cardsAlreadyDoubled],
														[
															...cardsAlreadyDestroyed,
															getHighestCardThatShouldBeZero([
																...selectedRobinsonCards,
																...additionalCardsDrawn,
																selectedCard,
															]),
														]
													)
												);

												setAdditionalCardsDrawn([
													...additionalCardsDrawn,
													selectedCard,
												]);
											},
										});
										// confirmation modal to spend a life point to continue picking another card
									} else {
										notification['warning']({
											message: 'Robinson deck',
											description:
												'You cannot start to fight without selecting hazard card.',
										});
									}
								}}
							/>
						) : (
							<div className="t-a:c marginTop-24">
								{outOfAgingCards ? (
									<p style={{ color: 'red' }}>
										<b>
											NOTE: You have no more aging cards. If you try to shuffle
											the robinson cards now, you will result in losing the
											game.
										</b>
									</p>
								) : (
									<p>
										Time to add a aging card to the robinson discard pile and
										shuffle it. Do the honors of clicking the button 😀
									</p>
								)}
								<Button
									onClick={() =>
										shuffleRobinsonCardsWithAnAgingCard(
											robinsonDeck,
											agingDeck,
											robinsonDiscardPile
										)
									}
								>
									Shuffle
								</Button>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className="row marginTop-24">
				<div className="col-xs-6 d:f p:r">
					{selectedRobinsonCards
						.filter((card) => card)
						.map((card, i) => (
							<div
								className="fCenter p:a"
								style={{ width: '100%', top: i === 0 ? '0px' : 250 * i + 'px' }}
								key={card}
							>
								{renderSelectedCard(card)}
							</div>
						))}
				</div>
				<div className="col-xs-6 d:f p:r">
					{additionalCardsDrawn
						.filter((card) => card)
						.map((card, i) => (
							<div
								className="fCenter p:a"
								style={{ width: '100%', top: i === 0 ? '0px' : 250 * i + 'px' }}
								key={card}
							>
								{renderSelectedCard(card)}
							</div>
						))}
				</div>
			</div>

			<Modal
				title="Select (click on) a Hazard card to continue"
				centered
				visible={hazardSelectionModal}
				okText="Continue"
				maskClosable={false}
				onOk={() =>
					hazardSelectionOnOkay(
						selectedHazard,
						hazardDiscardPile,
						selectedHazardCards
					)
				}
				onCancel={() => {
					// resetting the hazard deck and selected hazard cards
					let tmp = hazardDeck;

					while (selectedHazardCards.length > 0) {
						tmp.push(selectedHazardCards.pop());
					}

					_setSelectedHazard('');
					_setHazardDeck(tmp);
					_setSelectedHazardCards([]);
					_setHazardSelectionModal(false);
				}}
				width="800px"
			>
				<div className="row">
					{selectedHazardCards.map((card, i) => (
						<div className="col-xs-6 fCenter" key={card}>
							<Card
								id={card}
								key_id={`hazardCard_selection_${i}`}
								lifePoints={cards[card].lifePoints}
								fightValue={cards[card].fightValue}
								state={cards[card].state}
								specialAbility={cards[card].specialAbility}
								hazard
								showHazard
								diffcultLevel={cards[card].levels.hard}
								mediumLevel={cards[card].levels.medium}
								easyLevel={cards[card].levels.easy}
								hazardDescription={cards[card].hazard}
								freeCards={cards[card].freeCards}
								imageNum={cards[card].imageNum}
								onClickCallback={() => {
									_setSelectedHazard(card);
								}}
								front
								className={`${
									selectedHazard === card ? 'selected-no-rotation' : ''
								}`}
							/>
						</div>
					))}
				</div>
				{selectedHazard && (
					<div className="row">
						<div className="col-xs-12">
							<Descriptions
								title="Arsenal"
								column={1}
								contentStyle={{ fontWeight: 'bold' }}
								bordered
							>
								<Descriptions.Item label="Type">
									{cards[selectedHazard].state}
								</Descriptions.Item>
								<Descriptions.Item label="Fight value">
									{cards[selectedHazard].fightValue}
								</Descriptions.Item>
								<Descriptions.Item label="Special ability">
									{cards[selectedHazard].specialAbility
										? cards[selectedHazard].specialAbility
										: 'n/a'}
								</Descriptions.Item>
								<Descriptions.Item label="Life points">
									{cards[selectedHazard].lifePoints}
								</Descriptions.Item>
							</Descriptions>
						</div>
					</div>
				)}
			</Modal>

			<ResolveModal
				didWin={hasWonTheFight()}
				showTheResolveModal={showResolveModal}
				cardsAlreadyDestroyed={cardsAlreadyDestroyed}
				onOk={(totalPointsLost, cardsToRemove) => {
					if (!fightingPirates) {
						setTotalFights(totalFights + 1);
						if (hasWonTheFight()) {
							setTotalFightsWon(totalFightsWon + 1);
						}
					}

					subtractLife(totalPointsLost);
					setEliminatedCards([
						...eliminatedCards,
						...cardsToRemove,
						...cardsAlreadyDestroyed,
					]);

					setAlreadySelectedCards([]);

					let tempRobinsonDiscardPile = [
						...robinsonDiscardPile,
						...selectedRobinsonCards.filter(
							(c) =>
								!cardsToRemove.includes(c) && !cardsAlreadyDestroyed.includes(c)
						),
						...additionalCardsDrawn.filter(
							(c) =>
								!cardsToRemove.includes(c) && !cardsAlreadyDestroyed.includes(c)
						),
					];

					if (fightingPirates) {
						if (isPirateCard(selectedHazard)) {
							if (hasWonTheFight()) {
								setWonPirates([...wonPirates, selectedHazard]);
							} else {
								setLostPirates([...lostPirates, selectedHazard]);
							}
						}

						setFightingPirates(false);
					} else {
						if (hasWonTheFight()) {
							tempRobinsonDiscardPile = [
								...tempRobinsonDiscardPile,
								selectedHazard,
							];
						} else {
							_setHazardDiscardPile([...hazardDiscardPile, selectedHazard]);
						}
					}

					_setRobinsonDiscardPile(tempRobinsonDiscardPile);

					// reset
					_setSelectedHazard('');
					setSelectedRobinsonCards([]);
					setAdditionalCardsDrawn([]);
					setCardsAlreadyDestroyed([]);
					setCardsAlreadyDoubled([]);
					setCardsToSkip1xbelowResolve([]);
					setCardsToSkipDestroyResolve([]);

					_setIsFightOn(false);
					setCurrentFightScore(0);
					setStopped(false);
					setHighestCardIsZero(false);
					setStopDrawingFightCards(false);

					_setShowResolveModal(false);
					setShowTheResolveDoubleModal(false);

					setSkipTheCardToExchange('');
					setNumberOfCardsToExchange(0);

					setIsCopyCardActive(false);
					setShowTheResolveCopy(false);
					setSkipTheCardsToCopy([]);
					setCardsAlreadyCopied([]);

					setOnlyHalfTheCardsCountBeingFought(false);

					setSelectedSpecialAbilityCard('');

					setListOfAvailableSpeicalAbilities([]);

					if (reducedGameMode > 0) {
						_setGameMode(gameMode + reducedGameMode);
						setReducedGameMode(0);
					}
				}}
				cardsToResolve={getCardsToResolve}
				differenceInPoints={
					getFightScoreForTheMode(selectedHazard) - currentFightScore < 0
						? 0
						: getFightScoreForTheMode(selectedHazard) - currentFightScore
				}
			/>

			<ResolveDouble
				cardsToResolve={getCardsToResolve}
				alreadyDoubledCards={cardsAlreadyDoubled}
				cardsToSkipFromDoubling={[
					tempBarFromDoublingTheOwnCard,
					highestCardIsZeroCard,
				]}
				showTheResolveDouble={showTheResolveDoubleModal}
				callback={(card) => {
					setShowTheResolveDoubleModal(false);

					if (!card) {
						removeCardFromAlreadySelectedCards(selectedSpecialAbilityCard);
						return;
					}

					setStopDrawingFightCards(true);
					setTempBarFromDoublingTheOwnCard('');

					if (card) {
						setCardsAlreadyDoubled([...cardsAlreadyDoubled, card]);
					}

					setCurrentFightScore(
						getFightScore(
							[...selectedRobinsonCards, ...additionalCardsDrawn],
							[...cardsAlreadyDoubled, card],
							[
								...cardsAlreadyDestroyed,
								getHighestCardThatShouldBeZero([
									...selectedRobinsonCards,
									...additionalCardsDrawn,
								]),
							]
						)
					);

					updateCardWithSpecialAbility(selectedSpecialAbilityCard);

					setConsecutiveStepInCopyWithCopyCard('');
				}}
				cardsAlreadyDestroyed={cardsAlreadyDestroyed}
				onCancel={() => {
					setShowTheResolveDoubleModal(false);
					setTempBarFromDoublingTheOwnCard('');
					removeCardFromAlreadySelectedCards(selectedSpecialAbilityCard);

					resolveConsecutiveStepInCopyWithCopyCard();
				}}
			/>

			<Resolve1xbelow
				leftHandSideCards={selectedRobinsonCards.filter((card) => card)}
				rightHandSideCards={additionalCardsDrawn.filter((card) => card)}
				cardsToSkip1xbelowResolve={cardsToSkip1xbelowResolve}
				showTheResolve1xbelow={showTheResolve1xbelowModal}
				cantGetAnExchangeCard={stopDrawingFightCards}
				callback={(card, requestForExchangeCard = true) => {
					setShowTheResolve1xbelowModal(false);

					removeCardFromAlreadySelectedCards(card);

					let tmpSelectedRobinsonCards,
						tmpAdditionalCardsDrawn = [];

					// removing the selected card from both the decks
					tmpSelectedRobinsonCards = selectedRobinsonCards.filter(
						(c) => c !== card
					);

					tmpAdditionalCardsDrawn = additionalCardsDrawn.filter(
						(c) => c !== card
					);

					if (
						selectedRobinsonCards.length !== tmpSelectedRobinsonCards.length
					) {
						if (requestForExchangeCard) {
							tmpSelectedRobinsonCards.push(robinsonDeck.pop());
						} else {
							// makes sure an additional free card is not shown
							tmpSelectedRobinsonCards.push('');
						}

						setSelectedRobinsonCards([...tmpSelectedRobinsonCards]);
					}

					if (additionalCardsDrawn.length !== tmpAdditionalCardsDrawn.length) {
						setAdditionalCardsDrawn([...tmpAdditionalCardsDrawn]);
					}

					// moving the card below the deck
					const tmp = robinsonDeck;
					tmp.unshift(card);
					_setRobinsonDeck(tmp.filter((c) => c));

					let tmpHighestCardIsZero = highestCardIsZero;
					if (resetAgingPowers(card)) {
						if ('highestCardIsZero' in cards[card]) {
							setHighestCardIsZeroCard('');
							tmpHighestCardIsZero = false;
						}
					}

					setCurrentFightScore(
						getFightScore(
							[...tmpSelectedRobinsonCards, ...tmpAdditionalCardsDrawn],
							[...cardsAlreadyDoubled],
							[
								...cardsAlreadyDestroyed,
								card,
								tmpHighestCardIsZero
									? getHighestCardThatShouldBeZero([
											...tmpSelectedRobinsonCards,
											...tmpAdditionalCardsDrawn,
									  ])
									: '',
							]
						)
					);

					setCardsToSkip1xbelowResolve(
						cardsToSkip1xbelowResolve.filter(
							(c) => c !== selectedSpecialAbilityCard
						)
					);

					updateCardWithSpecialAbility(selectedSpecialAbilityCard);

					setConsecutiveStepInCopyWithCopyCard('');
				}}
				onCancel={() => {
					setCardsToSkip1xbelowResolve([]);
					setShowTheResolve1xbelowModal(false);
					removeCardFromAlreadySelectedCards(selectedSpecialAbilityCard);

					resolveConsecutiveStepInCopyWithCopyCard();
				}}
				alreadyUsedCards={() =>
					listOfAvailableSpeicalAbilities
						.filter((c) => c.alreadyUsed)
						.map((c) => c.card)
				}
			/>

			<ResolveDestroy
				showTheResolveDestroy={showTheResolveDestroyModal}
				cardsToResolve={getCardsToResolve}
				cardsToSkipDestroyResolve={cardsToSkipDestroyResolve}
				callback={(cardSelected) => {
					setCardsAlreadyDestroyed([...cardsAlreadyDestroyed, cardSelected]);
					setShowTheResolveDestroyModal(false);

					let tmpHighestCardIsZero = false;

					if (resetAgingPowers(cardSelected)) {
						if ('highestCardIsZero' in cards[cardSelected]) {
							[...selectedRobinsonCards, ...additionalCardsDrawn].forEach(
								(c) => {
									if (
										!cardsAlreadyDestroyed.includes(c) &&
										c !== cardSelected &&
										isAgingCard(c) &&
										'highestCardIsZero' in cards[c]
									) {
										tmpHighestCardIsZero = true;
									}
								}
							);

							let hcZero = '';

							if (tmpHighestCardIsZero) {
								hcZero = getHighestCardThatShouldBeZero(
									[...selectedRobinsonCards, ...additionalCardsDrawn].filter(
										(c) => c !== cardSelected
									)
								);
							}

							setHighestCardIsZeroCard(hcZero);
							setHighestCardIsZero(tmpHighestCardIsZero);
						}
					}

					setCardsExplicitlyDestroyed([
						...cardsExplicitlyDestroyed,
						cardSelected,
					]);

					setCurrentFightScore(
						getFightScore(
							[...selectedRobinsonCards, ...additionalCardsDrawn],
							[...cardsAlreadyDoubled],
							[
								...cardsAlreadyDestroyed,
								cardSelected,
								tmpHighestCardIsZero
									? getHighestCardThatShouldBeZero([
											...selectedRobinsonCards,
											...additionalCardsDrawn,
									  ])
									: '',
							]
						)
					);

					updateCardWithSpecialAbility(selectedSpecialAbilityCard);

					cardsToSkipDestroyResolve.pop(); // removing the destroy card itself that was added to the array from being non-destroyable.
					setCardsToSkipDestroyResolve(cardsToSkipDestroyResolve);

					setConsecutiveStepInCopyWithCopyCard('');
				}}
				onCancel={() => {
					setCardsToSkipDestroyResolve([]);
					setShowTheResolveDestroyModal(false);
					removeCardFromAlreadySelectedCards(selectedSpecialAbilityCard);

					resolveConsecutiveStepInCopyWithCopyCard();
				}}
			/>

			<ResolveSort3
				showTheResolveSort3={showTheResolveSort3Modal}
				top3CardsFromRobinsonDeck={top3CardsFromRobinsonDeck}
				callback={(discardCard, cardsInTheOrder) => {
					// null check before adding the discard card to the array.
					if (discardCard) {
						_setRobinsonDiscardPile([...robinsonDiscardPile, discardCard]);
					}
					_setRobinsonDeck(
						[...robinsonDeck, ...cardsInTheOrder].filter((c) => c)
					);
					setTop3CardsFromRobinsonDeck([]);
					setShowTheResolveSort3Modal(false);

					updateCardWithSpecialAbility(selectedSpecialAbilityCard);

					setConsecutiveStepInCopyWithCopyCard('');
				}}
			/>

			<ResolveExchange
				showTheResolveExchange={showTheResolveExchange}
				cardsAlreadyDestroyed={[
					...cardsAlreadyDestroyed,
					skipTheCardToExchange,
				]}
				cardsToResolve={getCardsToResolve}
				callback={(card) => {
					_setRobinsonDiscardPile([...robinsonDiscardPile, card]);

					removeCardFromAlreadySelectedCards(card);

					// making unique copies of the cards for the comparision operation
					const tmpSelectedRobinsonCards = [...selectedRobinsonCards];
					const tmpAdditionalCardsDrawn = [...additionalCardsDrawn];

					let tmpFilteredRobinsonCards = selectedRobinsonCards.filter(
						(c) => c !== card
					);

					let tmpFilteredAdditionalCards = additionalCardsDrawn.filter(
						(c) => c !== card
					);

					// logic to place the new exchanged card in the right place (left-RobinsonCards or right-AdditionalCards)
					if (
						tmpSelectedRobinsonCards.length !== tmpFilteredRobinsonCards.length
					) {
						tmpFilteredRobinsonCards = [
							...tmpFilteredRobinsonCards,
							robinsonDeck.pop(),
						];
					}

					if (
						tmpAdditionalCardsDrawn.length !== tmpFilteredAdditionalCards.length
					) {
						tmpFilteredAdditionalCards = [
							...tmpFilteredAdditionalCards,
							robinsonDeck.pop(),
						];
					}

					setSelectedRobinsonCards(tmpFilteredRobinsonCards);
					setAdditionalCardsDrawn(tmpFilteredAdditionalCards);
					_setRobinsonDeck([...robinsonDeck]);
					setShowTheResolveExchange(false);

					// score counting
					let tmpHighestCardIsZero = highestCardIsZero;
					if (resetAgingPowers(card)) {
						if ('highestCardIsZero' in cards[card]) {
							setHighestCardIsZeroCard('');
							tmpHighestCardIsZero = false;
						}
					}

					setCurrentFightScore(
						getFightScore(
							[...tmpFilteredRobinsonCards, ...tmpFilteredAdditionalCards],
							[...cardsAlreadyDoubled],
							[
								...cardsAlreadyDestroyed,
								card,
								tmpHighestCardIsZero
									? getHighestCardThatShouldBeZero([
											...tmpFilteredRobinsonCards,
											...tmpFilteredAdditionalCards,
									  ])
									: '',
							]
						)
					);

					updateCardWithSpecialAbility(selectedSpecialAbilityCard);

					if (numberOfCardsToExchange - 1 !== 0) {
						setTimeout(() => {
							setShowTheResolveExchange(true);
						}, 350);
					}

					setNumberOfCardsToExchange(numberOfCardsToExchange - 1);

					setConsecutiveStepInCopyWithCopyCard('');
				}}
				onCancel={() => {
					setShowTheResolveExchange(false);

					// undo the selected action if the exchange is not done even once
					if (
						cards[skipTheCardToExchange].exchange === numberOfCardsToExchange
					) {
						removeCardFromAlreadySelectedCards(selectedSpecialAbilityCard);
					}

					setSkipTheCardToExchange('');
					setNumberOfCardsToExchange(0);

					resolveConsecutiveStepInCopyWithCopyCard();
				}}
				alreadyUsedCards={() =>
					listOfAvailableSpeicalAbilities
						.filter((c) => c.alreadyUsed)
						.map((c) => c.card)
				}
			/>

			<ResolveCopy
				cardsToResolve={getCardsToResolve}
				showTheResolveCopy={showTheResolveCopy}
				cardsAlreadyDestroyed={[
					...cardsAlreadyDestroyed,
					...skipTheCardsToCopy,
				]}
				callback={(card) => {
					setIsCopyCardActive(true);
					setShowTheResolveCopy(false);

					setCardsAlreadyCopied([...cardsAlreadyCopied, card]);

					updateCardWithSpecialAbility(selectedSpecialAbilityCard);

					updateCardWithSpecialAbility(card, true);

					setTimeout(() => {
						document.getElementById(card).click();
					}, 350);
				}}
				onCancel={() => {
					setIsCopyCardActive(false);
					setShowTheResolveCopy(false);
					setSkipTheCardsToCopy([]);

					setConsecutiveStepInCopyWithCopyCard('');

					removeCardFromAlreadySelectedCards(selectedSpecialAbilityCard);
				}}
			/>

			<StartUpModal
				showTheStartUpModal={showStartupModal}
				callback={(selectedLevel, name, timeMode) => {
					_setShowStartupModal(false);

					if (selectedLevel <= 0 || selectedLevel > 4) {
						notification['warning']({
							message: 'Selected level',
							description: 'Invalid level selected!',
						});

						return;
					}

					if (selectedLevel > 1) {
						let tmpAgingCards = shuffle(agingCards);
						_setRobinsonDeck(shuffle([...robinsonDeck, tmpAgingCards.pop()]));

						if (selectedLevel === 2) {
							_setAgingDeck([
								...shuffle(superAgingCards),
								...shuffle([...tmpAgingCards]),
							]);
						} else if (selectedLevel === 3) {
							_setAgingDeck([
								...shuffle(superAgingCards),
								...shuffle([...tmpAgingCards, difficultAgingCard]),
							]);
						} else if (selectedLevel === 4) {
							_setAgingDeck([
								...shuffle(superAgingCards),
								...shuffle([...tmpAgingCards, difficultAgingCard]),
							]);
							setLifeInHand(18);
						}
					}

					setGameLevel(selectedLevel);
					setName(name);

					if (timeMode > 0) {
						setTimedMode(timeMode);
					}

					axios
						.post(
							process.env.REACT_APP_BACKEND_URL,
							{
								name,
								level: selectedLevel,
								time_mode:
									timeMode === 0 ? 'Un-timed' : `${timedModeMap[timeMode]} min`,
								pirate_card_1: randomPirateCards[0],
								pirate_card_2: randomPirateCards[1],
							},
							{
								headers: {
									'x-friday-token': getToken(),
								},
							}
						)
						.then((resp) => {
							setGameID(resp.data.id);
							let content =
								'No highscores found for this mode. Wish you luck to "Fly high"!';
							if (resp.data.high_score_in_selected_mode > 0) {
								content = `${resp.data.high_score_in_selected_mode} is the highscore in this setting. Hope you beat it!`;
							}

							message.info(content);
						})
						.catch((err) => {
							setShowNetworkIssueStatus(true);
							setInitialRequestFailed(true);
						});
				}}
			/>
			<Modal
				title={`Friday (level ${gameLevel})`}
				visible={showGameOverModal}
				footer={null}
				closable={false}
				maskClosable={false}
				className="t-a:c"
			>
				{isBaseGameCompleteRef.current && wonPirates.length === 2 && (
					<>
						<h2 style={{ color: 'green' }}>
							{isBaseGameCompleteRef.current &&
								wonPirates.length === 2 &&
								'You won the game!'}
						</h2>
					</>
				)}

				{wonPirates.length < 2 || !isBaseGameCompleteRef.current ? (
					<>
						<h2 style={{ color: 'red' }}>
							{(wonPirates.length < 2 || !isBaseGameCompleteRef.current) &&
								'You lost!'}
						</h2>
					</>
				) : (
					<></>
				)}

				{timedMode > 0 ? (
					remainingTime === 0 ? (
						<p>Timed out!</p>
					) : (
						<p>
							Completed in <b>{totalGameTime.str}</b>
						</p>
					)
				) : (
					<p>
						Completed in <b>{totalGameTime.str}</b>
					</p>
				)}

				<h4>
					Final score: <b>{score}</b>{' '}
					{wonTheGameRef.current && (
						<i
							onClick={() => _setShowScoreBreakdownModal(true)}
							className="c:p scoreBreakdownLink"
						>
							breakdown?
						</i>
					)}
				</h4>
				<h5>
					Click{' '}
					<a href="#" onClick={() => window.location.reload()}>
						here
					</a>{' '}
					to try again!
				</h5>
			</Modal>

			<Help
				visible={showHelpModal}
				onOk={() => {
					_setShowHelpModal(false);
				}}
			/>

			<div
				className="helpIcon"
				onClick={() => {
					_setShowHelpModal(true);
				}}
			/>

			<ScoreBreakdown
				cardsToPreview={[
					...selectedRobinsonCards,
					...additionalCardsDrawn,
					...robinsonDeck,
					...robinsonDiscardPile,
				]}
				finalScore={score}
				lifeInHand={lifeInHand}
				level={gameLevel}
				time={totalGameTime}
				timeMode={timedModeMap[timedMode]}
				name={name}
				totalFights={totalFights}
				totalFightsWon={totalFightsWon}
				wonPirates={wonPirates}
				visible={showScoreBreakdownModal}
				remainingHazardCards={[
					...hazardDiscardPile,
					...hazardDeck,
					isPirateCard(selectedHazard) ? '' : selectedHazard,
				].filter((c) => c)}
				onOk={() => {
					_setShowScoreBreakdownModal(false);
				}}
			/>
		</div>
	);
}

export default App;
