/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Radio, notification } from 'antd';
import Card from '../card/card';
import cards from '../../cards.json';

const ResolveCopy = (props) => {
	const [selectedCard, setSelectedCard] = useState([]);

	const cardsToRender = () => {
		let arrToReturn = [];

		if (props.cardsToResolve().length === 0) {
			return arrToReturn;
		}

		if (props.cardsToResolve && cards) {
			arrToReturn = props
				.cardsToResolve()
				.filter((card) => !props.cardsAlreadyDestroyed.includes(card));
		}

		if (arrToReturn.length === 0 && props.showTheResolveCopy) {
			notification['warning']({
				message: 'Resolve Copy',
				description: 'Not enough eligible cards to perform the resolve!',
			});
		}

		return arrToReturn;
	};

	const resetState = () => {
		setSelectedCard('');
	};

	return (
		<Modal
			className={`resolveCopy${props.className ? ` ${props.className}` : ''}`}
			visible={props.showTheResolveCopy}
			closable={false}
			afterClose={resetState}
			destroyOnClose
			footer={[
				<Button
					onClick={() => {
						if (props.onCancel) {
							props.onCancel();
						}
					}}
				>
					Cancel
				</Button>,
				<Button
					type="primary"
					disabled={!selectedCard}
					onClick={() => {
						if (props.callback) {
							props.callback(selectedCard);
						}
					}}
				>
					Done
				</Button>,
			]}
			width="100%"
		>
			<h2 className="t-a:c">
				Select a card to copy and use their special ability again
			</h2>

			<div className="t-a:c">
				{props.showTheResolveCopy &&
					cardsToRender().map((card) => (
						<div className={`d:i-flex marginLeft-24 marginTop-24`} key={card}>
							<Radio.Group
								onChange={(e) => {
									setSelectedCard(e.target.value);
								}}
								value={selectedCard}
							>
								{card.startsWith('h-') ? (
									<>
										<Radio value={card}>
											<Card
												id={card}
												front
												diffcultLevel={cards[card].levels.hard}
												mediumLevel={cards[card].levels.medium}
												easyLevel={cards[card].levels.easy}
												hazardDescription={cards[card].hazard}
												freeCards={cards[card].freeCards}
												imageNum={cards[card].imageNum}
												hazard
												lifePoints={cards[card].lifePoints}
												fightValue={cards[card].fightValue}
												state={cards[card].state}
												specialAbility={cards[card].specialAbility}
												className={`${
													selectedCard === card ? 'selected-no-rotation' : ''
												}`}
											/>
										</Radio>
									</>
								) : (
									<>
										<Radio value={card}>
											<Card
												id={card}
												front
												lifePoints={cards[card].lifePoints}
												fightValue={cards[card].fightValue}
												state={cards[card].state}
												specialAbility={cards[card].specialAbility}
												className={`${
													selectedCard === card ? 'selected-no-rotation' : ''
												}`}
											/>
										</Radio>
									</>
								)}
							</Radio.Group>
						</div>
					))}
			</div>
		</Modal>
	);
};

ResolveCopy.propTypes = {
	className: PropTypes.string,
	showTheResolveCopy: PropTypes.bool,
	cardsToResolve: PropTypes.func,
	callback: PropTypes.func,
	cardsAlreadyDestroyed: PropTypes.array,
	onCancel: PropTypes.func,
};

export default ResolveCopy;
