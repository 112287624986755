import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Tabs, Tooltip } from 'antd';
import content from './content.json';
import './help.css';

const { TabPane } = Tabs;

let macPrefixKey = '';

content.prefix.forEach((a) =>
	// ? a.platform needs to be updated everytime an update is made to the platform on content.json file
	a.platform === 'Mac' ? (macPrefixKey = a.key) : ''
);

const renderShortcut = (prefix, shortcut, index) => {
	const key =
		'mKey' in shortcut && prefix === macPrefixKey
			? shortcut.mKey
			: shortcut.key;
	return (
		<section className={`${index > 0 ? 'marginTop-12' : ''}`} key={key}>
			<div>
				<Tooltip title={shortcut.title}>
					<b>
						<u>{`${prefix} + ${key}`}</u>
					</b>
				</Tooltip>{' '}
				<small>{shortcut.description}</small>
			</div>
		</section>
	);
};

const Help = (props) => {
	const [prefix, setPrefix] = useState('shift');

	return (
		<Modal
			className={`help${props.className ? ` ${props.className}` : ''}`}
			visible={props.visible}
			closable
			onCancel={() => {
				if (props.onOk) {
					props.onOk();
				}
			}}
			footer={[
				<Button
					autoFocus
					onClick={() => {
						if (props.onOk) {
							props.onOk();
						}
					}}
				>
					Ok
				</Button>,
			]}
			width="80%"
		>
			<h3>Following are the available keyboard shortcuts:</h3>
			<Tabs
				size="small"
				defaultActiveKey={prefix}
				onChange={(p) => {
					setPrefix(p);
				}}
			>
				{content.prefix.map((p) => (
					<TabPane tab={p.platform} key={p.key}></TabPane>
				))}
			</Tabs>
			<div className="helpShortcuts">
				{content.shortcuts.map((shortcut, i) =>
					renderShortcut(prefix, shortcut, i)
				)}
			</div>
		</Modal>
	);
};

Help.propTypes = {
	className: PropTypes.string,
	visible: PropTypes.bool,
	onOk: PropTypes.func,
};

export default Help;
