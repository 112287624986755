/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Radio, notification } from 'antd';
import Card from '../card/card';
import cards from '../../cards.json';

const ResolveDouble = (props) => {
	const [selectedCard, setSelectedCard] = useState('');
	const [cardsToRender, setCardsToRender] = useState([]);

	useEffect(() => {
		if (props.showTheResolveDouble) {
			let arrToReturn = [];

			if (props.cardsToResolve().length === 0) {
				return arrToReturn;
			}

			if (props.cardsToResolve && cards) {
				arrToReturn = props
					.cardsToResolve()
					.filter(
						(card) =>
							!props.alreadyDoubledCards.includes(card) &&
							!props.cardsToSkipFromDoubling.includes(card) &&
							!props.cardsAlreadyDestroyed.includes(card)
					);
			}

			if (arrToReturn.length === 0 && props.showTheResolveDouble) {
				notification['warning']({
					message: 'Double resolve modal',
					description: 'Not enough eligible cards to perform the resolve!',
				});

				if (props.callback) {
					props.callback('');
				}

				return;
			}

			arrToReturn.sort((a, b) => cards[b].fightValue - cards[a].fightValue);

			setCardsToRender(arrToReturn);
		}
	}, [props.showTheResolveDouble]);

	const resetState = () => {
		setSelectedCard('');
	};

	return (
		<Modal
			className={`resolveDouble${props.className ? ` ${props.className}` : ''}`}
			visible={props.showTheResolveDouble}
			closable={false}
			afterClose={resetState}
			destroyOnClose
			footer={[
				<Button
					type="ghost"
					onClick={() => {
						if (props.onCancel) {
							props.onCancel();
						}
					}}
				>
					Cancel
				</Button>,
				<Button
					type="primary"
					disabled={!selectedCard}
					onClick={() => {
						if (props.callback) {
							props.callback(selectedCard);
						}
					}}
				>
					Done
				</Button>,
			]}
			width="100%"
		>
			<h2 className="t-a:c">Select a card to double up the fighting value</h2>

			<p className="t-a:c">
				<i>
					<b>Note</b>: Cards already doubled in this fight are not shown here.
				</i>
			</p>

			<div className="t-a:c">
				{props.showTheResolveDouble &&
					cardsToRender.map((card) => (
						<div className={`d:i-flex marginLeft-24 marginTop-24`} key={card}>
							<Radio.Group
								onChange={(e) => {
									if (e.target.value) {
										setSelectedCard(e.target.value);
									}
								}}
								value={selectedCard}
							>
								{card.startsWith('h-') ? (
									<>
										<Radio value={card}>
											<Card
												id={card}
												front
												diffcultLevel={cards[card].levels.hard}
												mediumLevel={cards[card].levels.medium}
												easyLevel={cards[card].levels.easy}
												hazardDescription={cards[card].hazard}
												freeCards={cards[card].freeCards}
												imageNum={cards[card].imageNum}
												hazard
												lifePoints={cards[card].lifePoints}
												fightValue={cards[card].fightValue}
												state={cards[card].state}
												specialAbility={cards[card].specialAbility}
												className={`${
													selectedCard === card ? 'selected-no-rotation' : ''
												}`}
											/>
										</Radio>
									</>
								) : (
									<>
										<Radio value={card}>
											<Card
												id={card}
												front
												lifePoints={cards[card].lifePoints}
												fightValue={cards[card].fightValue}
												state={cards[card].state}
												specialAbility={cards[card].specialAbility}
												className={`${
													selectedCard === card ? 'selected-no-rotation' : ''
												}`}
											/>
										</Radio>
									</>
								)}
							</Radio.Group>
						</div>
					))}
			</div>
		</Modal>
	);
};

ResolveDouble.propTypes = {
	className: PropTypes.string,
	showTheResolveDouble: PropTypes.bool,
	cardsToResolve: PropTypes.func,
	alreadyDoubledCards: PropTypes.array,
	callback: PropTypes.func,
	cardsToSkipFromDoubling: PropTypes.array,
	cardsAlreadyDestroyed: PropTypes.array,
	onCancel: PropTypes.func,
};

export default ResolveDouble;
