import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import Card from '../card/card';
import cards from '../../cards.json';
import './scoreBreakdown.css';

const ScoreBreakdown = (props) => {
	const [cardsToPreview, setCardsToPreview] = useState([]);
	const [agingCards, setAgingCards] = useState([]);
	const [robinsonCards, setRobinsonCards] = useState([]);
	const [scoreFromRobinsonCards, setScoreFromRobinsonCards] = useState(0);

	useLayoutEffect(() => {
		if (props.visible) {
			document.querySelector('.scoreBreakdown').parentElement.scroll(0, 0);
		}
	}, [props]);

	useLayoutEffect(() => {
		const tmpCards = props.cardsToPreview.filter((c) => c);

		const aCards = tmpCards.filter((c) => isAgingCard(c));
		setAgingCards(aCards);

		const rCards = tmpCards.filter((c) => !isAgingCard(c));
		setRobinsonCards(rCards);

		setCardsToPreview([...aCards, ...rCards]);

		if (rCards.length > 0) {
			setScoreFromRobinsonCards(
				rCards.map((c) => cards[c].fightValue).reduce((a, b) => a + b)
			);
		}
	}, [props.cardsToPreview]);

	const rotateCard = (element) => {
		const elem = element.currentTarget;
		if (elem.className.includes('rotate-180deg')) {
			elem.className = elem.className.replace(/rotate-180deg/g, '');
		} else {
			elem.className = elem.className + ' rotate-180deg';
		}
	};

	const filterCards = (cards) => {
		return cards.filter((card) => card);
	};

	const isAgingCard = (card) => {
		if (card) {
			return 'superAged' in cards[card];
		}

		return false;
	};

	return (
		<Modal
			className={`scoreBreakdown${
				props.className ? ` ${props.className}` : ''
			}`}
			visible={props.visible}
			closable
			onCancel={() => {
				if (props.onOk) {
					props.onOk();
				}
			}}
			footer={[
				<Button
					autoFocus
					onClick={() => {
						if (props.onOk) {
							props.onOk();
						}
					}}
				>
					Ok
				</Button>,
			]}
			width="100%"
		>
			<h2 className="t-a:c">Score breakdown</h2>

			<div className="container">
				<div className="row">
					<div className="col-xs-6 fCenter">
						<table>
							<tr>
								<td>
									<b>Name</b>:
								</td>
								<td>
									<b>{props.name}</b>
								</td>
							</tr>
							<tr>
								<td>
									<b>Level</b>:
								</td>
								<td>
									<b>{props.level}</b>
								</td>
							</tr>
							<tr>
								<td>
									<b>Time mode</b>:
								</td>
								<td>
									<b>
										{props.timeMode > 0 ? `${props.timeMode} min` : 'Un-timed'}
									</b>
								</td>
							</tr>
							<tr>
								<td>
									<b>Total time</b>:
								</td>
								<td>
									<b>{props.time.str}</b>
								</td>
							</tr>
							<tr>
								<td>
									<b>Fights (won | lost)</b>:
								</td>
								<td>
									<b>
										{props.totalFightsWon} W |{' '}
										{props.totalFights - props.totalFightsWon} L
									</b>
								</td>
							</tr>
						</table>
					</div>
					<div className="col-xs-6 fCenter">
						<table>
							<tr>
								<td>
									<b>Robinson cards</b>:
								</td>
								<td>
									<i>{robinsonCards.length}</i>{' '}
								</td>
								<td>
									<b>{scoreFromRobinsonCards}</b>
								</td>
							</tr>

							<tr>
								<td>
									<b>Pirates won</b>:
								</td>
								<td>
									<i>{props.wonPirates.length} x 15</i>{' '}
								</td>
								<td>
									<b>{props.wonPirates.length * 15}</b>
								</td>
							</tr>

							<tr>
								<td>
									<b>Life points</b>:
								</td>
								<td>
									<i>{props.lifeInHand} x 5</i>{' '}
								</td>
								<td>
									<b>{props.lifeInHand * 5}</b>
								</td>
							</tr>

							<tr>
								<td>
									<b>Aging cards</b>:
								</td>
								<td>
									<i>{agingCards.length} x -5</i>{' '}
								</td>
								<td>
									<b>{agingCards.length * -5}</b>
								</td>
							</tr>

							<tr>
								<td>
									<b>Hazard cards</b>:
								</td>
								<td>
									<i>{props.remainingHazardCards.length} x -3</i>{' '}
								</td>
								<td>
									<b>{props.remainingHazardCards.length * -3}</b>
								</td>
							</tr>
						</table>
					</div>
				</div>

				<div className="row">
					<div className="col-xs-12 t-a:c">
						<div style={{ fontSize: '25px' }}>
							<b>Total:</b> {props.finalScore}
						</div>
					</div>
				</div>
			</div>

			<div className="marginTop-24" style={{ fontSize: '15px' }}>
				Following list of cards (aging + robinson) contribute to your total:
			</div>

			<div className="scoreBreakdown-cards t-a:c">
				{props.visible &&
					cardsToPreview &&
					filterCards(cardsToPreview).map((card) => (
						<div
							className={`scoreBreakdown-cards-card d:i-flex marginLeft-24 marginTop-12`}
							key={card}
						>
							{card.startsWith('h-') ? (
								<>
									<Card
										id={card}
										front
										diffcultLevel={cards[card].levels.hard}
										mediumLevel={cards[card].levels.medium}
										easyLevel={cards[card].levels.easy}
										hazardDescription={cards[card].hazard}
										freeCards={cards[card].freeCards}
										imageNum={cards[card].imageNum}
										hazard
										lifePoints={cards[card].lifePoints}
										fightValue={cards[card].fightValue}
										state={cards[card].state}
										specialAbility={cards[card].specialAbility}
										onClickCallback={(ele) => {
											rotateCard(ele);
										}}
									/>
								</>
							) : (
								<>
									<Card
										id={card}
										front
										lifePoints={cards[card].lifePoints}
										fightValue={cards[card].fightValue}
										state={cards[card].state}
										specialAbility={cards[card].specialAbility}
										className={`${
											isAgingCard(card) ? 'preview-aging-card' : ''
										}`}
									/>
								</>
							)}
						</div>
					))}
			</div>
		</Modal>
	);
};

ScoreBreakdown.propTypes = {
	cardsToPreview: PropTypes.array,
	className: PropTypes.string,
	finalScore: PropTypes.number,
	level: PropTypes.number,
	lifeInHand: PropTypes.number,
	name: PropTypes.string,
	onOk: PropTypes.func,
	remainingHazardCards: PropTypes.array,
	time: PropTypes.object,
	timeMode: PropTypes.number,
	totalFights: PropTypes.number,
	totalFightsWon: PropTypes.number,
	visible: PropTypes.bool,
	wonPirates: PropTypes.array,
};

export default ScoreBreakdown;
