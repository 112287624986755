/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, notification, Modal } from 'antd';
import DiscardPreviewDeck from '../discardPreviewDeck/discardPreviewDeck';
import './navbar.css';

import lifePoint from '../../images/life_point.png';
import agedPerson from '../../images/aged_person.png';
import level from '../../images/level.png';
import score from '../../images/score.png';
import countdownTimerImg from '../../images/hourglass.png';
import pauseTimerImg from '../../images/pause_button.png';
import playTimerImg from '../../images/play_button.png';
import resumeImg from '../../images/resume_button.png';
import restartImg from '../../images/restart_button.png';

const NavBar = (props) => {
	const [showDiscardPileModal, setShowDiscardPileModal] = useState(false);
	const [cardsToPreview, setCardsToPreview] = useState([]);
	const [titleForDiscardModal, setTitleForDiscardModal] = useState('');
	const [isHazardDiscardPile, setIsHazardDiscardPile] = useState(false);
	const [countdownTimerIcon, setCountdownTimerIcon] =
		useState(countdownTimerImg);

	const [hazardDiscardPile, setHazardDiscardPile] = useState([]);
	const [robinsonDiscardPile, setRobinsonDiscardPile] = useState([]);
	const [eliminatedDiscardPile, setEliminatedDiscardPile] = useState([]);
	const [isTimerPaused, setIsTimerPaused] = useState(false);

	const hazardDiscardPileRef = useRef(hazardDiscardPile);
	const _setHazardDiscardPile = (data) => {
		hazardDiscardPileRef.current = data;
		setHazardDiscardPile(data);
	};

	const robinsonDiscardPileRef = useRef(robinsonDiscardPile);
	const _setRobinsonDiscardPile = (data) => {
		robinsonDiscardPileRef.current = data;
		setRobinsonDiscardPile(data);
	};

	const eliminatedDiscardPileRef = useRef(eliminatedDiscardPile);
	const _setEliminatedDiscardPile = (data) => {
		eliminatedDiscardPileRef.current = data;
		setEliminatedDiscardPile(data);
	};

	const showDiscardPileModalRef = useRef(showDiscardPileModal);
	const _setShowDiscardPileModal = (data) => {
		showDiscardPileModalRef.current = data;
		setShowDiscardPileModal(data);
	};

	const isTimerPausedRef = useRef(isTimerPaused);
	const _setIsTimerPaused = (data) => {
		isTimerPausedRef.current = data;
		setIsTimerPaused(data);
	};

	const counterRef = useRef(0);

	useLayoutEffect(() => {
		_setHazardDiscardPile(props.hazardDiscardPile.filter((c) => c));
	}, [props.hazardDiscardPile]);

	useLayoutEffect(() => {
		_setRobinsonDiscardPile(props.robinsonDiscardPile.filter((c) => c));
	}, [props.robinsonDiscardPile]);

	useLayoutEffect(() => {
		_setEliminatedDiscardPile(props.eliminatedDiscardPile.filter((c) => c));
	}, [props.eliminatedDiscardPile]);

	useLayoutEffect(() => {
		_setIsTimerPaused(props.isTimerPaused);
	}, [props.isTimerPaused]);

	const onKeyPress = (e) => {
		if (isTimerPausedRef.current) {
			return;
		}

		if (e.ctrlKey || e.shiftKey) {
			// hazard discard pile
			if (e.key.toLowerCase() === 'i') {
				e.preventDefault();

				if (counterRef.current % 2 === 0) {
					showDiscardPile(
						hazardDiscardPileRef.current,
						'Hazard discard pile',
						true
					);
				} else {
					_setShowDiscardPileModal(false);
				}

				counterRef.current++;
			}

			// robinson discard pile
			if (e.key.toLowerCase() === 'o') {
				e.preventDefault();

				if (counterRef.current % 2 === 0) {
					showDiscardPile(
						robinsonDiscardPileRef.current,
						'Robinson discard pile',
						false
					);
				} else {
					_setShowDiscardPileModal(false);
				}

				counterRef.current++;
			}

			// eliminated discard pile
			if (e.key.toLowerCase() === 'p') {
				e.preventDefault();
				if (counterRef.current % 2 === 0) {
					showDiscardPile(
						eliminatedDiscardPileRef.current,
						'Eliminated discard pile',
						false
					);
				} else {
					_setShowDiscardPileModal(false);
				}

				counterRef.current++;
			}
		}
	};

	useLayoutEffect(() => {
		const tmpFn = (e) => onKeyPress(e);

		document.addEventListener('keypress', tmpFn);

		return () => {
			document.removeEventListener('keypress', tmpFn);
		};
	}, []);

	const gameModeStyles = (color) => {
		if (!color) {
			return {};
		}

		return {
			backgroundColor: color,
			padding: '2px',
			borderRadius: '5px',
		};
	};

	const renderCardIcon = (color) => {
		return <div className={`cardIcon${color ? ` ${color}` : ''} d-i:f`} />;
	};

	const showDiscardPile = (
		dP = [],
		title = 'Discard pile!',
		isHazardDiscardPile = false
	) => {
		if (dP.length === 0) {
			notification['warning']({
				message: 'Discard pile',
				description: 'There are no cards in the deck!',
			});
			return;
		}

		setCardsToPreview(dP);
		_setShowDiscardPileModal(true);
		setTitleForDiscardModal(title);
		setIsHazardDiscardPile(isHazardDiscardPile);
	};

	const stopTimer = () => {
		if (props.canPause) {
			if (!isTimerPausedRef.current) {
				setCountdownTimerIcon(playTimerImg);
			} else {
				setCountdownTimerIcon(countdownTimerImg);
			}
			props.onChange(!isTimerPausedRef.current);
		}
	};

	return (
		<div className={`navBar${props.className ? ` ${props.className}` : ''}`}>
			<div className="row">
				<div className="col-xs-2 navBar-lifePoints d:f">
					<div className="fCenter">
						<Tooltip placement="bottom" title="Life points: available/reserve">
							<img
								src={lifePoint}
								width="20px"
								height="20px"
								alt="life_point"
							/>{' '}
							{props.lifePointsInHand}/{props.lifePointsInReserve}
						</Tooltip>
					</div>
				</div>

				<div className="col-xs-2 navBar-level d:f">
					<div className="fCenter">
						<Tooltip placement="bottom" title="Game level">
							<img src={level} width="25px" height="25px" alt="aged_person" />{' '}
							{props.gameMode === 1 ? (
								<span style={gameModeStyles('lightgreen')}>Easy</span>
							) : props.gameMode === 2 ? (
								<span style={gameModeStyles('yellow')}>Medium</span>
							) : props.gameMode === 3 ? (
								<span style={gameModeStyles('orange')}>Hard</span>
							) : (
								<span style={gameModeStyles('lightgray')}>Pirates</span>
							)}
						</Tooltip>
					</div>
				</div>

				<div className="col-xs-2 navBar-score d:f">
					<div className="fCenter">
						<Tooltip
							placement="bottom"
							title="Score: Fight score from robinson cards / score required to win the selected hazard card (available free cards)"
						>
							<img src={score} width="20px" height="20px" alt="score" />{' '}
							{props.score}
						</Tooltip>
					</div>
				</div>

				<div
					className={`col-xs-2 navBar-countdownTimer ${
						props.timedMode > 0 && !props.isFightOn ? 'c:p' : ''
					} d:f`}
				>
					<div
						onMouseOver={() =>
							!isTimerPausedRef.current && props.canPause
								? setCountdownTimerIcon(pauseTimerImg)
								: null
						}
						onMouseOut={() =>
							!isTimerPausedRef.current && props.canPause
								? setCountdownTimerIcon(countdownTimerImg)
								: null
						}
						className="fCenter"
						onClick={() => stopTimer()}
					>
						<Tooltip
							placement="bottom"
							title={`Countdown timer ${
								props.timedMode > 0 && props.isFightOn
									? '(to pause, complete the current fight)'
									: props.timedMode > 0
									? '(click to pause)'
									: ''
							}`}
						>
							<img
								src={countdownTimerIcon}
								width="20px"
								height="20px"
								style={{ marginRight: '2px' }}
								alt="countdown timer"
							/>
							{props.countdownTimer ? props.countdownTimer : 'n/a'}
						</Tooltip>
					</div>
				</div>

				<div className="col-xs-1 navBar-agedCards d:f">
					<div className="fCenter">
						<Tooltip
							placement="bottom"
							title="Remaining aging cards in the deck"
						>
							<img
								src={agedPerson}
								width="25px"
								height="25px"
								alt="aged_person"
							/>{' '}
							{props.totalAgingCards}
						</Tooltip>
					</div>
				</div>

				<div className="col-xs-1 navBar-hazardDiscardPile d:f">
					<div
						className="fCenter c:p"
						onClick={() =>
							showDiscardPile(hazardDiscardPile, 'Hazard discard pile', true)
						}
					>
						<Tooltip
							placement="bottom"
							className="fCenter"
							title="Hazard discard pile"
						>
							{renderCardIcon('red')}&nbsp;
							{hazardDiscardPile ? hazardDiscardPile.length : 0}
						</Tooltip>
					</div>
				</div>

				<div className="col-xs-1 navBar-robinsonDiscardPile d:f">
					<div
						className="fCenter c:p"
						onClick={() =>
							showDiscardPile(
								robinsonDiscardPile,
								'Robinson discard pile',
								false
							)
						}
					>
						<Tooltip
							placement="bottom"
							className="fCenter"
							title="Robinson discard pile"
						>
							{renderCardIcon('blue')}&nbsp;
							{robinsonDiscardPile ? robinsonDiscardPile.length : 0}
						</Tooltip>
					</div>
				</div>

				<div className="col-xs-1 navBar-elimintaedCardsPile d:f">
					<div
						className="fCenter c:p"
						onClick={() =>
							showDiscardPile(
								eliminatedDiscardPile,
								'Eliminated discard pile',
								false
							)
						}
					>
						<Tooltip
							placement="bottom"
							className="fCenter"
							title="Eliminated cards pile"
						>
							{renderCardIcon('gray')}&nbsp;
							{eliminatedDiscardPile ? eliminatedDiscardPile.length : 0}
						</Tooltip>
					</div>
				</div>
			</div>

			<DiscardPreviewDeck
				visible={showDiscardPileModal}
				onOk={() => {
					_setShowDiscardPileModal(false);
				}}
				cardsToPreview={cardsToPreview}
				title={titleForDiscardModal}
				isHazardDiscardPile={isHazardDiscardPile}
			/>

			<Modal
				className="navBar-gameOptionsModal"
				visible={isTimerPausedRef.current}
				footer={null}
				closable={false}
			>
				<div className="navBar-gameOptionsModal-wrapper">
					<div className="fCenter navBar-gameOptionsModal-wrapper-headline">
						<h1>
							<b>Game options</b>
						</h1>
					</div>
					<div className="navBar-gameOptionsModal-wrapper-buttonsHolder t-a:c">
						<div className="marginTop-12">
							<img
								src={resumeImg}
								onClick={stopTimer}
								alt="Resume icon"
								width="100px"
								className="c:p"
							/>
							<div>
								<h2>
									<b className="c:p" onClick={stopTimer}>
										Resume
									</b>
								</h2>
							</div>
						</div>

						<div className="marginTop-48">
							<img
								src={restartImg}
								onClick={() => window.location.reload()}
								alt="Restart icon"
								width="25px"
								className="c:p"
							/>
							<div>
								<h4>
									<b className="c:p" onClick={() => window.location.reload()}>
										Restart
									</b>
								</h4>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};

NavBar.propTypes = {
	className: PropTypes.string,
	lifePointsInHand: PropTypes.number,
	lifePointsInReserve: PropTypes.number,
	totalAgingCards: PropTypes.number,
	gameMode: PropTypes.number,
	score: PropTypes.string,
	countdownTimer: PropTypes.string,
	isTimerPaused: PropTypes.bool,
	canPause: PropTypes.bool,
	hazardDiscardPile: PropTypes.array,
	robinsonDiscardPile: PropTypes.array,
	eliminatedDiscardPile: PropTypes.array,
	onChange: PropTypes.func,
	timedMode: PropTypes.number,
	isFightOn: PropTypes.bool,
};

export default NavBar;
