const shuffle = (array) => {
	for (let i = array.length - 1; i > 0; i--) {
		let j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
};

const validateObject = (obj, arr, defaultVal = false) => {
	if (arr.length === 0 || !obj) {
		return defaultVal;
	}

	let tempObj = obj;
	for (let i = 0; i < arr.length; i += 1) {
		if (tempObj[arr[i]] || tempObj[arr[i]] === 0) {
			tempObj = tempObj[arr[i]];
		} else {
			return defaultVal;
		}
	}

	return tempObj;
};

const numPadding = (num) => {
	let resp = num;

	if (num >= 0 && num < 10) {
		resp = `0${num}`;
	}

	return resp;
};

module.exports = {
	shuffle,
	validateObject,
	numPadding,
};
