/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Modal, Radio } from 'antd';
import Card from '../card/card';
import cards from '../../cards.json';

const Resolve1xbelow = (props) => {
	const [selectedCard, setSelectedCard] = useState('');
	const [exchangeCardPreference, setExchangeCardPreference] = useState(true);
	const [
		exchangeCardPreferenceCheckboxDisbaled,
		setExchangeCardPreferenceCheckboxDisabled,
	] = useState(true);
	const [abilitiesAlreadyUsed, setAbilitiesAlreadyUsed] = useState([]);

	useLayoutEffect(() => {
		if (props.showTheResolve1xbelow) {
			setAbilitiesAlreadyUsed(props.alreadyUsedCards());
		}
	}, [props.alreadyUsedCards, props.showTheResolve1xbelow]);

	const cardsToRender = (side) => {
		let arrToReturn = [];

		if (side.length === 0) {
			return arrToReturn;
		}

		arrToReturn = side.filter(
			(card) => !props.cardsToSkip1xbelowResolve.includes(card)
		);

		arrToReturn.sort((a, b) => cards[a].fightValue - cards[b].fightValue);

		return arrToReturn;
	};

	const resetState = () => {
		setSelectedCard('');
		setExchangeCardPreference(true);
		setExchangeCardPreferenceCheckboxDisabled(true);
		setAbilitiesAlreadyUsed([]);
	};

	const exchangeCardPreferenceCallBack = (e) => {
		setExchangeCardPreference(e.target.checked);
	};

	return (
		<Modal
			className={`resolve1xbelow${
				props.className ? ` ${props.className}` : ''
			}`}
			visible={props.showTheResolve1xbelow}
			closable={false}
			afterClose={resetState}
			destroyOnClose
			footer={[
				<Checkbox
					onChange={exchangeCardPreferenceCallBack}
					defaultChecked={exchangeCardPreference}
					disabled={
						props.cantGetAnExchangeCard ||
						exchangeCardPreferenceCheckboxDisbaled
					}
				>
					Do you want an exchange card?
				</Checkbox>,
				<Button
					type="ghost"
					onClick={() => {
						if (props.onCancel) {
							props.onCancel();
						}
					}}
				>
					Cancel
				</Button>,
				<Button
					type="primary"
					disabled={!selectedCard}
					onClick={() => {
						if (props.callback) {
							props.callback(selectedCard, exchangeCardPreference);
						}
					}}
				>
					Done
				</Button>,
			]}
			width="100%"
		>
			<h2 className="t-a:c">Resolve 1x below the pile</h2>
			<p className="t-a:c">
				<i>
					<b>Note</b>: Used cards are tilted and have a brown background.
				</i>
			</p>

			<div className="t-a:c">
				{[props.leftHandSideCards, props.rightHandSideCards].map((p, i) => {
					return (
						<div key={p.length + i}>
							{p && p.length > 0 && (
								<h3>
									{i === 0
										? `Select a card to place it below the pile ${
												!props.cantGetAnExchangeCard
													? '+ get an additional fight card drawn (optional)'
													: ''
										  }`
										: `Select a card to place it below the pile ${
												!props.cantGetAnExchangeCard
													? "+ you won't get an additional fight card drawn"
													: ''
										  }`}
								</h3>
							)}
							{cardsToRender(p).map((card) => (
								<div
									className={`d:i-flex marginLeft-24 marginTop-24`}
									key={card}
								>
									<Radio.Group
										onChange={(e) => {
											setExchangeCardPreferenceCheckboxDisabled(i === 1);

											setSelectedCard(e.target.value);
										}}
										value={selectedCard}
									>
										{card.startsWith('h-') ? (
											<>
												<Radio value={card}>
													<Card
														id={card}
														front
														diffcultLevel={cards[card].levels.difficult}
														mediumLevel={cards[card].levels.medium}
														easyLevel={cards[card].levels.easy}
														hazardDescription={cards[card].hazard}
														freeCards={cards[card].freeCards}
														imageNum={cards[card].imageNum}
														hazard
														lifePoints={cards[card].lifePoints}
														fightValue={cards[card].fightValue}
														state={cards[card].state}
														specialAbility={cards[card].specialAbility}
														className={`${
															selectedCard === card
																? 'selected-no-rotation'
																: abilitiesAlreadyUsed.includes(card)
																? 'ability-already-used'
																: ''
														}`}
													/>
												</Radio>
											</>
										) : (
											<>
												<Radio value={card}>
													<Card
														id={card}
														front
														lifePoints={cards[card].lifePoints}
														fightValue={cards[card].fightValue}
														state={cards[card].state}
														specialAbility={cards[card].specialAbility}
														className={`${
															selectedCard === card
																? 'selected-no-rotation'
																: abilitiesAlreadyUsed.includes(card)
																? 'ability-already-used'
																: ''
														}`}
													/>
												</Radio>
											</>
										)}
									</Radio.Group>
								</div>
							))}
						</div>
					);
				})}
			</div>
		</Modal>
	);
};

Resolve1xbelow.propTypes = {
	className: PropTypes.string,
	showTheResolve1xbelow: PropTypes.bool,
	rightHandSideCards: PropTypes.array,
	leftHandSideCards: PropTypes.array,
	cardsToSkip1xbelowResolve: PropTypes.array,
	callback: PropTypes.func,
	onCancel: PropTypes.func,
	cantGetAnExchangeCard: PropTypes.bool,
	alreadyUsedCards: PropTypes.func,
};

export default Resolve1xbelow;
