import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import Card from '../card/card';
import cards from '../../cards.json';

const DiscardPreviewDeck = (props) => {
	const rotateCard = (element) => {
		const elem = element.currentTarget;
		if (elem.className.includes('rotate-180deg')) {
			elem.className = elem.className.replace(/rotate-180deg/g, '');
		} else {
			elem.className = elem.className + ' rotate-180deg';
		}
	};

	const filterCards = (cards) => {
		return cards.filter((card) => card !== '');
	};

	const isAgingCard = (card) => {
		if (card) {
			return 'superAged' in cards[card];
		}

		return false;
	};

	return (
		<Modal
			className={`discardPreviewDeck${
				props.className ? ` ${props.className}` : ''
			}`}
			visible={props.visible}
			closable
			onCancel={() => {
				if (props.onOk) {
					props.onOk();
				}
			}}
			footer={[
				<Button
					autoFocus
					onClick={() => {
						if (props.onOk) {
							props.onOk();
						}
					}}
				>
					Ok
				</Button>,
			]}
			width="100%"
		>
			<h2 className="t-a:c">{props.title}</h2>
			<div className="discardPreviewDeck-cards t-a:c">
				{props.cardsToPreview &&
					filterCards(props.cardsToPreview).map((card) => (
						<div
							className={`discardPreviewDeck-cards-card d:i-flex marginLeft-24 marginTop-12`}
							key={card}
						>
							{card.startsWith('h-') ? (
								<>
									<Card
										id={card}
										front
										diffcultLevel={cards[card].levels.hard}
										mediumLevel={cards[card].levels.medium}
										easyLevel={cards[card].levels.easy}
										hazardDescription={cards[card].hazard}
										freeCards={cards[card].freeCards}
										imageNum={cards[card].imageNum}
										hazard
										lifePoints={cards[card].lifePoints}
										fightValue={cards[card].fightValue}
										state={cards[card].state}
										specialAbility={cards[card].specialAbility}
										onClickCallback={(ele) => {
											rotateCard(ele);
										}}
										className={`${
											props.isHazardDiscardPile ? 'rotate-180deg' : ''
										}`}
									/>
								</>
							) : (
								<>
									<Card
										id={card}
										front
										lifePoints={cards[card].lifePoints}
										fightValue={cards[card].fightValue}
										state={cards[card].state}
										specialAbility={cards[card].specialAbility}
										className={`${
											isAgingCard(card) ? 'preview-aging-card' : ''
										}`}
									/>
								</>
							)}
						</div>
					))}
			</div>
		</Modal>
	);
};

DiscardPreviewDeck.propTypes = {
	className: PropTypes.string,
	visible: PropTypes.bool,
	onOk: PropTypes.func,
	cardsToPreview: PropTypes.array,
	title: PropTypes.string,
	isHazardDiscardPile: PropTypes.bool,
};

export default DiscardPreviewDeck;
